<script>
  // # # # # # # # # # # # # #
  //
  //  TOOLBAR
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import { links, navigate } from "svelte-routing"
  import { createEventDispatcher } from "svelte"
  // import get from "lodash/get"
  const dispatch = createEventDispatcher()

  // *** STORES
  // import {
  //   localUserAuthenticated,
  //   authenticatedUserInformation,
  // } from "../stores"

  // *** PROPS
  export let section = ""

  // *** VARIABLES
  let chatInputValue = ""
  let showChatInput = false

  const submitChat = () => {
    dispatch("submit", {
      text: chatInputValue,
    })
    chatInputValue = ""
  }

  const teleport = () => {
    dispatch("teleport")
  }

  let notificationCount = 0

  // const getNotifications = () => {
  //   if (
  //     $localUserAuthenticated &&
  //     get($authenticatedUserInformation, "username", false)
  //   ) {
  //     let username = $authenticatedUserInformation.username
  //     fetch("https://sso.post-rational.org/notifications?user=" + username)
  //       .then(response => response.json())
  //       .then(data => {
  //         // console.log("polling notifications in toolbar", data)
  //         notificationCount = get(
  //           data,
  //           "notifications.notifications",
  //           []
  //         ).filter(n => n.notification_type === 6 && !n.read).length
  //         // console.log("notificationCount", notificationCount)
  //       })
  //       .catch(err => {
  //         console.error(err)
  //       })
  //   }
  // }

  // getNotifications()

  // setInterval(getNotifications, 10000)
</script>

<div class="toolbar" use:links>
  <!-- {#if $localUserAuthenticated && !showChatInput} -->
  <!-- CHAT => open full input -->
  <!-- <div
      class="toolbar-item chat"
      on:click={e => {
        showChatInput = true
        navigate('/')
      }}>
      Chat
    </div> -->
  <!-- SEMINAR -->
  <!-- <a
      href="/seminar"
      class="toolbar-item"
      class:active={section === 'seminar'}>
      Seminar</a> -->
  <!-- MESSAGES -->
  <!-- <a
      href="/messages"
      class="toolbar-item"
      class:active={section === 'messages'}>Messages{#if notificationCount > 0}
        ({notificationCount})
      {/if}</a>
    <div class="toolbar-item" on:click={teleport}>Support</div>
  {:else} -->
  <!-- CHAT -->
  <input
    placeholder="Write a message..."
    type="[text]"
    aria-label="Chat message"
    maxlength="600"
    bind:value={chatInputValue}
    on:keydown={e => {
      if (e.keyCode == 13) submitChat()
    }}
  />
  <button aria-label="Send chat message" type="submit" on:click={submitChat}
    >Send</button
  >
  <!-- {#if $localUserAuthenticated}
      <button
        class="close-chat"
        on:click={e => {
          showChatInput = false
        }}>×</button>
    {/if} -->
  <!-- {/if} -->
</div>

<style lang="scss">.toolbar {
  width: 100%;
  height: 100%;
  padding-left: 7px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  user-select: none; }
  .toolbar input {
    font-family: "WorkSans", sans-serif;
    font-size: 16px;
    float: left;
    width: calc(100% - 60px);
    display: block;
    background: transparent;
    border: 1px solid #999999;
    color: #f0f0f0;
    border-radius: 20px;
    padding: 12px;
    height: 30px;
    margin-right: 12px; }
    .toolbar input.smaller {
      width: calc(100% - 110px); }
    .toolbar input:focus {
      border: 1px solid #f0f0f0; }
    .toolbar input input[type="text"] {
      -webkit-appearance: none; }
      .toolbar input input[type="text"]::placeholder {
        color: #f0f0f0; }
    .toolbar input select {
      -webkit-appearance: none; }
  .toolbar button {
    font-family: "WorkSans", sans-serif;
    font-size: 16px;
    width: 60px;
    float: right;
    display: block;
    background: transparent;
    border: 1px solid #999999;
    color: #f0f0f0;
    border-radius: 20px;
    cursor: pointer;
    height: 30px;
    line-height: 20px; }
    .toolbar button:hover {
      border: 1px solid #f0f0f0; }
    .toolbar button.close-chat {
      width: 40px;
      margin-left: 12px; }
  .toolbar .toolbar-item {
    font-size: 16px;
    margin-right: 12px;
    float: left;
    color: #f0f0f0;
    cursor: pointer;
    text-decoration: none;
    padding: 6px 2px;
    height: 30px; }
    .toolbar .toolbar-item:hover {
      text-decoration: underline; }
    .toolbar .toolbar-item.active {
      text-decoration: underline; }
    .toolbar .toolbar-item.chat {
      border: 1px solid #999999;
      border-radius: 20px;
      padding: 5px;
      text-decoration: none;
      padding-right: 24px;
      padding-left: 24px; }
      .toolbar .toolbar-item.chat:hover {
        border: 1px solid #f0f0f0; }
</style>
