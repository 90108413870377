<script>
  // # # # # # # # # # # # # #
  //
  //  EVENT LIST
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import get from "lodash/get";

  // *** GLOBAL
  import { formatDate } from "../global.js";

  // COMPONENTS
  import ParticipantList from "./ParticipantList.svelte";

  // *** PROPS
  export let events = [];
  export let exhibitions = [];
  export let related = false;
  export let showArchived = false;

  // *** VARIABLES
  let containerWidth = "100%";

  const now = Date.now();
  // __ HACK: Show all events if related
  const upcomingEvents = related
    ? events
    : events.filter(
        (e) => Date.parse(e.endDate ? e.endDate : e.startDate) > now
      );
  const archivedEvents = events.filter(
    (e) => Date.parse(e.endDate ? e.endDate : e.startDate) < now
  );

  onMount(async () => {
    // __ Enabled horizontal scroll layout on mobile
    if (window.matchMedia("(max-width: 800px)").matches && !related) {
      containerWidth = window.innerWidth * 0.8 * events.length + "px";
    }
  });
</script>

<div class="eventlist-container" style={"width:" + containerWidth + ";"}>
  <!-- HEADER -->
  <div class="event header" class:related>
    <div class="inner">
      <div class="row">
        {#if related}
          <div>Related Events</div>
          <a href="/events" class="archive-link">Archived events</a>
        {:else}
          <a href="/events" aria-label="View All Events">Events</a>
          <a href="/events" class="archive-link">Archived events</a>
          <!-- <div on:click={e => {showArchive = !showArchive}} class="archive-link">{showArchive ? 'Upcoming Events' : 'Event Archive'}</div> -->
        {/if}
      </div>
    </div>
  </div>

  <!-- EVENTS -->
  <div class="inner-container">
    {#each showArchived ? archivedEvents : upcomingEvents as event, index (event._id)}
      <a
        class="event"
        class:related
        in:fade={{ delay: 100 * index }}
        href={"/events/" + get(event, "slug.current", "")}
      >
        <div class="inner">
          <div class="row">
            <h2 class="title">{event.title}</h2>
            <!-- <div class="elips">
              .........................................................
            </div> -->
            <div class="date">{formatDate(event.startDate)}</div>
          </div>
          <div class="row">
            <div class="participants">
              {#if get(event, "moderators", false) && Array.isArray(event.moderators)}
                <ParticipantList participants={event.moderators} isModerators />
              {/if}
              {#if get(event, "participants", false) && Array.isArray(event.participants)}
                <ParticipantList participants={event.participants} />
              {/if}
            </div>
          </div>
        </div>
      </a>
    {/each}
  </div>

  <!-- FOOTER -->
  {#if !related && exhibitions && exhibitions.length > 0}
    <div class="footer">
      {#each exhibitions.reverse() as exhibition, index (exhibition._id)}
        <a
          href={"/area/" + get(exhibition, "area.slug.current", "")}
          class="exhibition"
        >
          <div class="inner">
            <div class="row">
              <h2 class="title">{exhibition.title}</h2>
              {#if exhibition.period}
                <div class="elips" role="presentation">
                  .........................................................
                </div>
                <div class="date">{exhibition.period}</div>
              {/if}
            </div>
          </div>
        </a>
      {/each}
    </div>
  {/if}
</div>

<style lang="scss">.eventlist-container {
  height: 100%;
  color: black;
  font-size: 16px;
  background: #f0f0f0;
  display: flex;
  flex-direction: column;
  overflow: hidden; }
  @media (max-width: 800px) {
    .eventlist-container {
      padding-top: 0; } }
  .eventlist-container .inner-container {
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none; }
    .eventlist-container .inner-container::-webkit-scrollbar {
      display: none; }
    @media (max-width: 800px) {
      .eventlist-container .inner-container {
        height: 100%; } }
  .eventlist-container .event {
    padding: 12px;
    width: 100%;
    min-height: 60px;
    background: #f0f0f0;
    color: black;
    display: block;
    text-decoration: none;
    user-select: none;
    overflow: hidden;
    transition: background 0.5s cubic-bezier(0.23, 1, 0.32, 1); }
    @media (max-width: 800px) {
      .eventlist-container .event {
        width: 80vw;
        display: inline-flex;
        padding-top: 12px;
        height: 80px;
        border-right: 1px solid #c5c5c5; } }
    .eventlist-container .event.related {
      padding-left: 0;
      padding-right: 0; }
      @media (max-width: 800px) {
        .eventlist-container .event.related {
          display: block;
          width: 100%;
          border-right: unset; } }
    .eventlist-container .event .inner {
      width: 100%; }
      .eventlist-container .event .inner .row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: baseline; }
        .eventlist-container .event .inner .row .title {
          font-size: 16px;
          font-family: "WorkSans", sans-serif;
          font-weight: 600;
          max-width: 70%;
          margin-bottom: 3px;
          margin-right: 3px; }
        .eventlist-container .event .inner .row .elips {
          margin-left: 6px;
          margin-right: 6px;
          width: 30%;
          white-space: nowrap;
          overflow: hidden;
          flex-shrink: 4;
          color: #999999; }
        .eventlist-container .event .inner .row .date {
          font-family: "WorkSans", sans-serif;
          font-weight: normal;
          font-size: 90%;
          white-space: nowrap;
          text-align: right;
          color: #555555; }
        .eventlist-container .event .inner .row .participants {
          font-family: "WorkSans", sans-serif;
          font-weight: normal;
          pointer-events: none;
          color: #555555;
          font-size: 14px; }
    .eventlist-container .event:hover {
      background: #c5c5c5; }
    .eventlist-container .event.header {
      height: 45px;
      border-bottom: 1px solid #c5c5c5;
      padding-bottom: 12px;
      min-height: unset;
      font-weight: 600; }
      .eventlist-container .event.header a {
        text-decoration: none; }
        .eventlist-container .event.header a:hover {
          text-decoration: underline; }
      .eventlist-container .event.header .archive-link {
        cursor: pointer;
        font-size: 90%;
        color: #999999;
        text-decoration: underline;
        transition: color 250ms cubic-bezier(0.23, 1, 0.32, 1); }
        .eventlist-container .event.header .archive-link:hover {
          text-decoration: none; }
      @media (max-width: 800px) {
        .eventlist-container .event.header {
          display: none; } }
      .eventlist-container .event.header.related {
        border-bottom: 1px dotted #c5c5c5; }
        @media (max-width: 800px) {
          .eventlist-container .event.header.related {
            display: block;
            position: static; } }
      .eventlist-container .event.header:hover {
        background: unset; }
  .eventlist-container .footer {
    height: 60px;
    border-top: 1px solid #c5c5c5; }
    .eventlist-container .footer:hover {
      background: unset; }
    @media (max-width: 800px) {
      .eventlist-container .footer {
        display: none; } }
    .eventlist-container .footer .exhibition {
      padding: 0px 12px;
      padding-top: 6px;
      width: 100%;
      height: 36px;
      background: #f0f0f0;
      color: black;
      display: block;
      text-decoration: none;
      user-select: none;
      overflow: hidden;
      transition: background 0.5s cubic-bezier(0.23, 1, 0.32, 1); }
      @media (max-width: 800px) {
        .eventlist-container .footer .exhibition {
          width: 80vw;
          display: inline-flex;
          padding-top: 12px;
          height: 80px;
          border-right: 1px solid #c5c5c5; } }
      .eventlist-container .footer .exhibition .inner {
        width: 100%; }
        .eventlist-container .footer .exhibition .inner .row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: baseline; }
          .eventlist-container .footer .exhibition .inner .row .title {
            font-size: 16px;
            font-family: "WorkSans", sans-serif;
            font-weight: 600;
            white-space: nowrap;
            max-width: 70%;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: 3px; }
          .eventlist-container .footer .exhibition .inner .row .elips {
            margin-left: 6px;
            margin-right: 6px;
            width: 80%;
            white-space: nowrap;
            overflow: hidden;
            flex-shrink: 999999;
            color: #999999; }
          .eventlist-container .footer .exhibition .inner .row .date {
            font-family: "WorkSans", sans-serif;
            font-weight: normal;
            font-size: 90%;
            white-space: nowrap;
            color: #555555; }
          .eventlist-container .footer .exhibition .inner .row .participants {
            font-family: "WorkSans", sans-serif;
            font-weight: normal;
            pointer-events: none;
            color: #555555;
            font-size: 14px; }
      .eventlist-container .footer .exhibition:hover {
        background: #c5c5c5; }
</style>
