<script>
  // # # # # # # # # # # # # #
  //
  //  MENU
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import { createEventDispatcher } from "svelte"
  import MediaQuery from "svelte-media-query"

  // *** CONSTANTS
  const dispatch = createEventDispatcher()
</script>

<div class="menu-container">
  <nav>
    <!-- <a href="/pages/schedule" class="menu-item">Schedule</a> -->
    <a href="/projects" class="menu-item">Artist Projects</a>
    <a href="/pages/schedule" class="menu-item">Schedule</a>
    <a href="/pages/guide" class="menu-item">Guide</a>
    <a href="/pages/about" class="menu-item">About</a>
  </nav>

  <MediaQuery query="(min-width: 768px)" let:matches>
    {#if matches}
    <span
      aria-label="Change your name for interaction"
      role="button"
      tabindex="0"
      on:click={e => {
        dispatch("username")
      }}
      class="menu-item login">Change name</span
    >
  {/if}
</MediaQuery>

</div>

<style lang="scss">.menu-container {
  height: 100%;
  color: black;
  font-size: 14px;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  padding-left: 12px;
  padding-right: 12px; }
  .menu-container .menu-item {
    font-weight: bold;
    padding-right: 24px;
    float: left;
    cursor: pointer;
    color: black;
    text-decoration: none; }
    .menu-container .menu-item:hover {
      text-decoration: underline; }
  .menu-container .login {
    padding-right: 0px;
    justify-self: end; }
</style>
