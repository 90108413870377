<script>
  // # # # # # # # # # # # # #
  //
  //  SINGLE PROJECT
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import { onMount } from "svelte"
  import get from "lodash/get"
  import { fade } from "svelte/transition"
  import { renderBlockText } from "../sanity.js"
  import { links } from "svelte-routing"
  import { createEventDispatcher } from "svelte"

  // *** COMPONENTS
  import ParticipantList from "../lists/ParticipantList.svelte"
  import EventList from "../lists/EventList.svelte"
  import MaterialItem from "./MaterialItem.svelte"
  import MetaData from "../MetaData.svelte"

  // *** PROPS
  export let project = {}

  const dispatch = createEventDispatcher()

  // *** VARIABLES
  let el = {}

  // *** ON MOUNT
  onMount(async () => {
      el.querySelector('h2').focus()
    })
</script>

<!-- METADATA -->
<MetaData post={project} />

<div class="project-single" bind:this={el} in:fade use:links>
  <!-- HEADER -->

  {#if project.title}
    <!-- BACK LINK -->
    <div
      class="go-back"
      aria-label="Go back"
      role="button"
      tabindex="0"
      on:click={e => {
        window.history.back()
      }}
    >
      <svg
        role="presentation"
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        ><path
          d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"
        /></svg
      >
    </div>
    <div class="main-header">
      <!-- COLOR CODE  -->
      <div class="color-icon {project.category}" role="presentation" />
      <!-- TITLE -->
      <h2 
        class="title"
        tabindex="0"
      >{project.title}</h2>
      <!-- PARTICIPANTS -->
      {#if get(project, "participants", false) && Array.isArray(project.participants)}
        <div class="participants">
          <ParticipantList
            participants={project.participants}
            messaging={true}
          />
        </div>
      {/if}
      <!-- GO TO PROJECT-->
      <div class="go-to-project">
        <div 
          class='go-to-project-inner'
          aria-label="Go to project location on map"
          role="button"
          tabindex="0"
          on:click={e => {
            dispatch("goToProject", {x: project.x, y: project.y})
          }}>
          <span>Go to project location on map</span>
          <svg
            xmlns="http://www.w3.org/2000/svg" 
            width="24" 
            height="24"
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            stroke-width="2" 
            stroke-linecap="round" 
            stroke-linejoin="round" 
            class="feather feather-map-pin">
            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
            <circle cx="12" cy="10" r="3"></circle>
          </svg>
        </div>
      </div>
    </div>
    <div class="divider" />
  {/if}

  <!-- TEXT -->
  {#if Array.isArray(get(project, "content.content", false))}
    <div class="text">
      {@html renderBlockText(project.content.content)}
    </div>
    <div class="divider" />
  {/if}

  <!-- MATERIAL -->
  {#if Array.isArray(project.material)}
    <div class="material">
      <div class="material-item header">
        <div class="row">
          <h2 class="title">Artist Media</h2>
          <div class="format">Type</div>
        </div>
      </div>
      {#each project.material as item (item._key)}
        <MaterialItem {item} />
      {/each}
    </div>
    <div class="divider" />
  {/if}

  <!-- RELATED EVENTS -->
  {#if Array.isArray(get(project, "connectedEvents", false))}
    <div class="related-events">
      <EventList events={project.connectedEvents} related={true} />
    </div>
  {/if}
</div>

<style lang="scss">.project-single .go-back {
  padding-top: 6px;
  padding-left: 12px;
  cursor: pointer; }
  .project-single .go-back svg path {
    fill: #999999;
    transition: fill 0.3s cubic-bezier(0.23, 1, 0.32, 1); }
  .project-single .go-back:hover svg path {
    fill: #555555; }

.project-single .go-to-project {
  display: inline-block;
  width: 100%; }
  .project-single .go-to-project .go-to-project-inner {
    cursor: pointer;
    float: right;
    font-size: 12px;
    color: #999999;
    transition: color 0.3s cubic-bezier(0.23, 1, 0.32, 1); }
    .project-single .go-to-project .go-to-project-inner svg {
      width: 16px;
      height: 16px;
      position: relative;
      top: 2px; }
      .project-single .go-to-project .go-to-project-inner svg path, .project-single .go-to-project .go-to-project-inner svg circle {
        stroke: #999999;
        transition: stroke 0.3s cubic-bezier(0.23, 1, 0.32, 1); }
    .project-single .go-to-project .go-to-project-inner:hover {
      color: #555555; }
      .project-single .go-to-project .go-to-project-inner:hover svg path, .project-single .go-to-project .go-to-project-inner:hover svg circle {
        stroke: #555555; }

.project-single .main-header {
  padding: 18px;
  padding-top: 6px; }
  .project-single .main-header .color-icon {
    display: none;
    height: 20px;
    width: 20px;
    margin-right: 18px;
    float: left;
    background: #ed3656; }
    .project-single .main-header .color-icon.communication {
      background: #1cc227; }
    .project-single .main-header .color-icon.consensus-building {
      background: #a2dafd; }
    .project-single .main-header .color-icon.sensing {
      background: #fff538; }
    .project-single .main-header .color-icon.archiving {
      background: #f8c2e8; }
  .project-single .main-header .title {
    font-family: "WorkSans", sans-serif;
    font-size: 28px;
    font-weight: 600; }
  .project-single .main-header .participants {
    margin-top: 6px;
    color: #555555;
    font-family: "WorkSans", sans-serif;
    font-size: 14px;
    display: inline-block; }

.project-single .divider {
  border-bottom: 1px dotted #c5c5c5;
  width: 100%; }

.project-single .material {
  padding: 18px; }

.project-single .text {
  padding: 18px 18px 0 18px;
  font-family: "WorkSans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4em;
  letter-spacing: 0.02em; }
  @media (max-width: 800px) {
    .project-single .text {
      padding-bottom: 18px; } }

.project-single .related-events {
  padding: 18px; }

.material-item {
  width: 100%;
  background: #f0f0f0;
  color: black;
  display: block;
  text-decoration: none;
  user-select: none;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer; }
  .material-item .row {
    width: 100%;
    display: flex;
    justify-content: space-between; }
    .material-item .row .title {
      font-family: "WorkSans", sans-serif;
      font-weight: 600;
      white-space: nowrap; }
    .material-item .row .elips {
      margin-left: 6px;
      margin-right: 6px;
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      flex-shrink: 4;
      color: #999999; }
    .material-item .row .format {
      white-space: nowrap;
      color: #999999; }
  .material-item:hover {
    background: #c5c5c5; }
  .material-item.header {
    padding-top: 0px;
    border-bottom: 1px dotted #c5c5c5;
    cursor: default; }
    .material-item.header .archive-link {
      color: #999999;
      text-decoration: underline; }
    .material-item.header:hover {
      background: unset; }
</style>
