<script>
  // # # # # # # # # # # # # #
  //
  //  PARTICIPANT LIST
  //
  // # # # # # # # # # # # # #

  // IMPORTS
  import get from "lodash/get"

  // *** STORES
  // import { localUserAuthenticated } from "../stores"

  // *** CONFIGURATION
  // import { MESSAGING_ENABLED } from "./world.config.js"

  // *** PROPS
  export let participants = {}
  export let messaging = false
  export let isModerators = false
  export let isSeminar = false
</script>

{#if participants && Array.isArray(participants)}
  {#each participants as participant, index}
    <span class="participant" class:seminar={isSeminar}>
      <a href={"/profiles/" + get(participant, "slug.current", "")}>
        {participant.name}
        {#if isModerators}
          &nbsp;(moderator)
        {/if}
      </a>
    </span>
  {/each}
{/if}

<style lang="scss">a {
  color: inherit;
  text-decoration: none; }
  a:hover {
    color: black; }
  a svg {
    margin-left: 6px;
    margin-right: 2px; }
    a svg path {
      fill: #999999; }
    a svg:hover path {
      fill: black; }

.seminar a {
  color: inherit;
  text-decoration: none; }
  .seminar a:hover {
    color: #f0f0f0; }
  .seminar a svg {
    margin-left: 6px;
    margin-right: 2px; }
    .seminar a svg path {
      fill: #999999; }
    .seminar a svg:hover path {
      fill: #f0f0f0; }

.participant::after {
  content: ", "; }

.participant:last-of-type::after {
  content: ""; }
</style>
