<script>
  // # # # # # # # # # # # # #
  //
  //  CLOCK
  //
  // # # # # # # # # # # # # #

  // const berlinTime = () => {
  //   const d = new Date()
  //   const cet = new Intl.DateTimeFormat("de-DE", {
  //     timeZone: "CET",
  //     hour: "numeric",
  //     minute: "numeric",
  //   }).format(d)
  //   return cet
  // }

  const newYorkTime = () => {
    const d = new Date()
    const est = new Intl.DateTimeFormat("en-US", {
      timeZone: "EST",
      hour: "numeric",
      minute: "numeric",
    }).format(d)
    return est
  }

  let time = newYorkTime()

  window.setInterval(() => {
    time = newYorkTime()
  }, 10000)
</script>

<!-- 
<div class="clock-berlin">
  {time}&nbsp;CET
</div> -->
<div class="clock-new-york" aria-label="Current time" aria-hidden="true">
  <span>{time}</span>EST
</div>

<style lang="scss">.clock-new-york {
  font-family: "WorkSans", sans-serif;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.8);
  color: #f0f0f0;
  z-index: 1001;
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px;
  word-spacing: -0.3em; }
  .clock-new-york a {
    color: white;
    text-decoration: none; }
  .clock-new-york span {
    margin-right: 3px; }
  @media (max-width: 800px) {
    .clock-new-york {
      top: 80px;
      z-index: 1; } }
</style>
