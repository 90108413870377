<script>
  // # # # # # # # # # # # # #
  //
  //  SINGLE USER PROFILE
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import { onMount } from "svelte"
  import get from "lodash/get"
  import { fade } from "svelte/transition"
  import { renderBlockText, loadData } from "../sanity.js"
  import { links } from "svelte-routing"

  // *** GLOBALS
  import { QUERY } from "../global.js"

  // COMPONENTS
  import EventList from "../lists/EventList.svelte"
  import ProjectList from "../lists/ProjectList.svelte"
  import MetaData from "../MetaData.svelte"

  // *** STORES
  // import { localUserAuthenticated } from "../stores"

  // *** PROPS
  export let user = {}

  // *** VARIABLES
  let relatedProjects = false
  let relatedEvents = false
  let el = {}

  const relatedContent = loadData(QUERY.CONNECTED_TO_USER, {
    id: user._id,
  }).catch(err => {
    console.log(err)
  })

  relatedContent.then(relatedContent => {
    // __ Filter related content by type
    if (relatedContent && Array.isArray(relatedContent)) {
      relatedEvents = relatedContent.filter(c => c._type == "event")
      relatedProjects = relatedContent.filter(c => c._type == "project")
    }
  })

  // *** ON MOUNT
  onMount(async () => {
    el.querySelector('h2').focus()
  })
</script>

<!-- METADATA -->
<MetaData post={user} />

<div class="user-profile-single" bind:this={el} in:fade use:links>
  <!-- BACK LINK -->
  <div
    class="go-back"
    aria-label="Go back"
    role="button"
    tabindex="0"
    on:click={e => {
      window.history.back()
    }}
  >
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      ><path
        d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"
      /></svg
    >
  </div>
  <!-- HEADER -->
  <div class="main-header">
    <!-- TITLE -->
    <h2 
    tabindex="0"
    class="title">
      {user.name}
    </h2>
  </div>
  <div class="divider" role="presentation" />

  <!-- BIOGRAPHY -->
  {#if Array.isArray(get(user, "biography.content", false))}
    <div class="text">
      {@html renderBlockText(user.biography.content)}
    </div>
    <div class="divider" />
  {/if}

  <!-- RELATED EVENTS -->
  <div class="related-events">
    {#if relatedEvents && Array.isArray(relatedEvents) && relatedEvents.length > 0}
      <EventList events={relatedEvents} related={true} />
    {/if}
  </div>

  <!-- CONNECTED PROJECTS -->
  <div class="connected-projects">
    {#if relatedProjects && Array.isArray(relatedProjects) && relatedProjects.length > 0}
      <ProjectList projects={relatedProjects} related={true} />
    {/if}
  </div>
</div>

<style lang="scss">.user-profile-single .go-back {
  padding-top: 12px;
  padding-left: 12px;
  cursor: pointer; }
  .user-profile-single .go-back svg path {
    fill: #999999;
    transition: fill 0.3s cubic-bezier(0.23, 1, 0.32, 1); }
  .user-profile-single .go-back:hover svg path {
    fill: #555555; }

.user-profile-single .user-profile-message svg path {
  fill: #999999;
  transition: fill 0.3s cubic-bezier(0.23, 1, 0.32, 1); }

.user-profile-single .user-profile-message:hover svg path {
  fill: #555555; }

.user-profile-single .main-header {
  padding: 18px; }
  .user-profile-single .main-header .title {
    font-family: "WorkSans", sans-serif;
    font-size: 28px;
    font-weight: bold; }
  .user-profile-single .main-header .participants {
    margin-top: 6px;
    color: #999999;
    font-family: "WorkSans", sans-serif;
    font-size: 14px;
    display: inline-block; }

.user-profile-single .divider {
  border-bottom: 1px dotted #c5c5c5;
  width: 100%; }

.user-profile-single .text {
  padding: 18px 18px 0 18px;
  font-family: "WorkSans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4em;
  letter-spacing: 0.02em; }
  @media (max-width: 800px) {
    .user-profile-single .text {
      padding-bottom: 18px; } }

.user-profile-single .related-events {
  padding: 18px; }

.user-profile-single .connected-projects {
  padding: 18px;
  padding-top: 0; }
</style>
