<script>
  // # # # # # # # # # # # # #
  //
  //  CHAT MESSAGE
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import { fade } from "svelte/transition"
  import anchorme from "anchorme"

  // *** GLOBALS
  import { formattedChatDate } from "../global.js"

  // *** PROPS
  export let message = {}

  // *** STORES
  // import { localUserName } from "../stores"
</script>

<div class="chat-message" transition:fade|local>
  <div class="meta">
    <span class="name">{message.name}</span>
    <span class="date">{formattedChatDate(message.timestamp)}</span>
  </div>
  <p class="body">
    {@html anchorme({
      input: message.text,
      options: {
        attributes: {
          target: "_blank",
        },
      },
    })}
  </p>
</div>

<style lang="scss">.chat-message {
  margin-bottom: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  color: #f0f0f0;
  font-size: 14px;
  user-select: none; }
  .chat-message .meta {
    width: 100%;
    display: inline-block;
    color: #999999; }
    .chat-message .meta .name {
      float: left; }
      .chat-message .meta .name svg path {
        fill: #999999; }
      .chat-message .meta .name svg:hover path {
        fill: #f0f0f0; }
    .chat-message .meta .date {
      font-family: "WorkSans", sans-serif;
      font-weight: normal;
      float: right; }
  .chat-message .body {
    display: block;
    border-left: 1px solid #999999;
    padding-left: 12px;
    padding-right: 6px;
    margin: 0; }
  .chat-message.broadcast .body {
    color: yellow; }
  .chat-message.narrowcast .body {
    color: green; }
</style>
