<script>
  // # # # # # # # # # # # # #
  //
  //  SINGLE EVENT
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import { afterUpdate, onMount } from "svelte"
  import get from "lodash/get"
  import { fade } from "svelte/transition"
  import { renderBlockText } from "../../sanity.js"
  import { links } from "svelte-routing"

  // *** COMPONENTS
  import ParticipantList from "../../lists/ParticipantList.svelte"
  import ProjectList from "../../lists/ProjectList.svelte"
  import MetaData from "../../MetaData.svelte"

  // *** GLOBAL
  import { formatDate } from "../../global.js"

  // *** PROPS
  export let event = {}

  // *** VARIABLES
  let el = {}

  // *** ON MOUNT
  onMount(async () => {
    el.querySelector('h2').focus()
  })

  // ** AFTER UPDATE
  afterUpdate(async () => {
    el.querySelector('h2').focus()
  })
</script>

<!-- METADATA -->
<MetaData post={event} />

<div class="event-single" bind:this={el} in:fade use:links>
  <!-- BACK LINK -->
  <div
    class="go-back"
    aria-label="Go back"
    role="button"
    tabindex="0"
    on:click={e => {
      window.history.back()
    }}
  >
    <svg
      role="presentation"
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      ><path
        d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"
      /></svg
    >
  </div>
  <!-- HEADER -->
  <div class="main-header">
    <!-- TITLE -->
    <h2 
      class="title"
      tabindex="0"
    >{event.title}</h2>

    <!-- PARTICIPANTS -->
    <div class="participants">
      {#if get(event, "moderators", false) && Array.isArray(event.moderators)}
        <ParticipantList
          participants={event.moderators}
          isModerators
          messaging={true}
        />
      {/if}
      {#if get(event, "participants", false) && Array.isArray(event.participants)}
        <ParticipantList participants={event.participants} messaging={true} />
      {/if}
    </div>
  </div>
  <div class="divider" />

  <!-- DATE -->
  <div class="date">{formatDate(event.startDate)}</div>
  <div class="divider" role="presentation" />

  <!-- TEXT -->
  {#if Array.isArray(get(event, "content.content", false)) && event.content.content.length > 0}
    <div class="text">
      {@html renderBlockText(event.content.content)}
    </div>
    <div class="divider" role="presentation" />
  {/if}

  <!-- CONNECTED CASE STUDIES -->
  <div class="connected-projects">
    {#if Array.isArray(get(event, "connectedProjects")) && event.connectedProjects.length > 0}
      <ProjectList projects={event.connectedProjects} related={true} />
    {/if}
  </div>
</div>

<style lang="scss">.event-single .go-back {
  padding-top: 6px;
  padding-left: 12px;
  cursor: pointer; }
  .event-single .go-back svg path {
    fill: #999999;
    transition: fill 0.3s cubic-bezier(0.23, 1, 0.32, 1); }
  .event-single .go-back:hover svg path {
    fill: #555555; }

.event-single .main-header {
  padding: 18px; }
  .event-single .main-header .title {
    font-family: "WorkSans", sans-serif;
    font-size: 28px;
    font-weight: 600; }
  .event-single .main-header .participants {
    margin-top: 6px;
    color: #555555;
    font-family: "WorkSans", sans-serif;
    font-size: 14px;
    display: inline-block; }

.event-single .divider {
  border-bottom: 1px dotted #c5c5c5;
  width: 100%; }

.event-single .date {
  padding: 18px;
  text-align: center;
  font-weight: 600;
  font-family: "WorkSans", sans-serif; }

.event-single .image {
  width: 100%;
  padding-top: 18px;
  padding-bottom: 18px; }
  .event-single .image img {
    width: 100%;
    max-height: 300px;
    object-fit: cover; }

.event-single .text {
  padding: 18px 18px 0 18px;
  font-family: "WorkSans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4em;
  letter-spacing: 0.02em; }
  @media (max-width: 800px) {
    .event-single .text {
      padding-bottom: 18px; } }

.event-single .connected-projects {
  padding: 18px; }
</style>
