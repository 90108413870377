<script>
  // # # # # # # # # # # # # #
  //
  //  Single Page
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import { afterUpdate, onMount } from "svelte"
  import get from "lodash/get"
  import { fade } from "svelte/transition"
  import { renderBlockText } from "../sanity.js"
  import { links } from "svelte-routing"

  // *** COMPONENTS
  import MetaData from "../MetaData.svelte"

  // *** PROPS
  export let page = {}

  // *** VARIABLES
  let el = {}

  // *** ON MOUNT
  onMount(async () => {
    el.querySelector('h2').focus()
  })

  afterUpdate(async () => {
    el.querySelector('h2').focus()
  })
</script>

<!-- METADATA -->
<MetaData post={page} />

<div class="page-single" bind:this={el} in:fade use:links>
  <!-- HEADER -->
  <div class="main-header">
    <!-- TITLE -->
    <h2 
    class="title"
    tabindex="0"
    >{page.title}</h2>
  </div>
  <div class="divider" />

  <!-- TEXT -->
  {#if Array.isArray(get(page, "content.content", false))}
    <div class="text">
      {@html renderBlockText(page.content.content)}
    </div>
    <div class="divider" />
  {/if}
</div>

<style lang="scss">.page-single .main-header {
  padding: 18px; }
  .page-single .main-header .title {
    font-family: "WorkSans", sans-serif;
    font-size: 28px;
    font-weight: 600; }
  .page-single .main-header .participants {
    margin-top: 6px;
    color: #999999;
    font-family: "WorkSans", sans-serif;
    font-size: 14px;
    display: inline-block; }

.page-single .divider {
  border-bottom: 1px dotted #c5c5c5;
  width: 100%; }

.page-single .text {
  padding: 18px 18px 0 18px;
  font-family: "WorkSans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4em;
  letter-spacing: 0.02em; }
  @media (max-width: 800px) {
    .page-single .text {
      padding-bottom: 18px; } }

.page-single .related-events {
  padding: 18px; }
</style>
