<script>
  // # # # # # # # # # # # # #
  //
  //  CHAT
  //
  // # # # # # # # # # # # # #

  // *** IMPORT
  import { afterUpdate, onMount } from "svelte"
  import { fade } from "svelte/transition"
  import { renderBlockText, loadData, client } from "../sanity"
  import get from "lodash/get"

  // *** COMPONENTS
  import ChatMessage from "./ChatMessage.svelte"

  // *** DOM REFERENCES
  let messageContainerEl = {}

  // *** GLOBAL
  import { QUERY } from "../global.js"

  // *** PROPS
  export let chatMessages = []
  export let currentRoom = 2
  export let mobile = false
  export let mobileExpanded = false

  // *** VARIABLES
  let pinnedText = false
  let pinnedMessageClosed = false

  loadData(QUERY.PINNED_MESSAGE)
    .then(pM => {
      if (
        pM.showPinnedMessage &&
        Array.isArray(get(pM, "content.content", false))
      ) {
        pinnedText = pM.content.content
        pinnedMessageClosed = false
      } else {
        pinnedText = false
      }
    })
    .catch(err => {
      console.log(err)
    })

  // __ Listen for changes to the pinned message
  client.listen(QUERY.PINNED_MESSAGE).subscribe(update => {
    pinnedText = false
    setTimeout(() => {
      loadData(QUERY.PINNED_MESSAGE)
        .then(pM => {
          if (
            pM.showPinnedMessage &&
            Array.isArray(get(pM, "content.content", false))
          ) {
            pinnedText = pM.content.content
            pinnedMessageClosed = false
          } else {
            pinnedText = false
          }
        })
        .catch(err => {
          console.log(err)
        })
    }, 500)
  })

  afterUpdate(() => {
    if (messageContainerEl) {
      messageContainerEl.scrollTo({
        top: messageContainerEl.scrollHeight,
        left: 0,
      })
    }
  })

  onMount(async () => {
    if (messageContainerEl) {
      setTimeout(() => {
        messageContainerEl.scrollTo({
          top: messageContainerEl.scrollHeight,
          left: 0,
        })
      }, 2000)
    }
  })
</script>

<div class="chat-container">
  {#if mobile && mobileExpanded}
    <div class="header">You are in: {currentRoom}</div>
  {/if}
  <div
    id="message-container"
    class="message-container"
    class:expanded={mobileExpanded}
    bind:this={messageContainerEl}
  >
    {#if pinnedText && !pinnedMessageClosed}
      <div class="pinned-message" transition:fade|local>
        {@html renderBlockText(pinnedText)}
        <div
          class="close-pinned-message"
          aria-label="Close pinned message"
          role="button"
          tabindex="0"
          on:click={e => {
            pinnedMessageClosed = true
          }}
        >
          ×
        </div>
      </div>
    {/if}
    {#each chatMessages as message (message.msgId)}
      <ChatMessage {message} />
    {/each}
  </div>
</div>

<style lang="scss">.chat-container {
  position: relative;
  height: 100%;
  overflow: scroll; }
  @media (max-width: 800px) {
    .chat-container {
      height: calc(100% - 40px); } }

.header {
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 7px;
  padding-bottom: 7px;
  width: 100%;
  color: #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  text-align: left; }

.message-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 12px; }
  .message-container .pinned-message {
    background: black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 6px;
    color: #f0f0f0;
    font-size: 14px;
    user-select: none;
    border-bottom: 1px solid #999999;
    pointer-events: all; }
    @media (max-width: 800px) {
      .message-container .pinned-message {
        position: fixed;
        top: 80px; } }
  @media (max-width: 800px) {
    .message-container {
      height: 100%;
      padding-bottom: 0;
      padding-top: 0; }
      .message-container.expanded {
        padding-bottom: 12px;
        padding-top: 12px;
        height: calc(100% - 28px); } }

.close-pinned-message {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 22px;
  cursor: pointer; }
</style>
