<script>
  // # # # # # # # # # # # # #
  //
  //  World View
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import { onMount } from "svelte"
  import * as Colyseus from "colyseus.js"
  import * as PIXI from "pixi.js"
  import { Viewport } from "pixi-viewport"
  import get from "lodash/get"
  import sample from "lodash/sample"
  import { fly, scale, fade } from "svelte/transition"
  import { quartOut } from "svelte/easing"
  import { urlFor, loadData, client } from "./sanity.js"
  import { links, navigate } from "svelte-routing"
  import MediaQuery from "svelte-media-query"
  import Tweener from "tweener"
  import Cookies from "js-cookie"

  // *** COMPONENTS
  // sidebar
  import Chat from "./sidebar/Chat.svelte"
  import MiniMap from "./sidebar/MiniMap.svelte"
  import Menu from "./sidebar/Menu.svelte"
  import ToolBar from "./sidebar/ToolBar.svelte"
  import Clock from "./sidebar/Clock.svelte"
  // lists
  import EventList from "./lists/EventList.svelte"
  import EventListFull from "./lists/EventListFull.svelte"
  import EventListSliderMobile from "./lists/EventListSliderMobile.svelte"
  import ProjectList from "./lists/ProjectList.svelte"
  // singles
  import ProjectSingle from "./singles/ProjectSingle.svelte"
  import PageSingle from "./singles/PageSingle.svelte"
  import UserProfileSingle from "./singles/UserProfileSingle.svelte"
  import EventSingle from "./singles/event/EventSingle.svelte"
  import LiveSingle from "./singles/event/LiveSingle.svelte"
  // import AudioInstallationSingle from "./singles/AudioInstallationSingle.svelte"
  // overlays
  import LoadingScreen from "./overlays/LoadingScreen.svelte"
  import Error from "./overlays/Error.svelte"
  import Reconnection from "./overlays/Reconnection.svelte"
  import Tutorial from "./overlays/Tutorial.svelte"
  import UsernameDialog from "./overlays/UsernameDialog.svelte"
  // ...
  import MetaData from "./MetaData.svelte"
  import Card from "./Card.svelte"

  // *** CONFIGURATION
  import { GAME_SERVER_URL, LINK_OUT, LINK_OUT_TEXT } from "./world.config.js"

  // *** GLOBAL
  import {
    nanoid,
    MAP,
    // TINTMAP,
    REVERSE_HEX_MAP,
    QUERY,
    TEXT_ROOMS,
    TEXT_STYLE_AVATAR,
    TEXT_STYLE_AVATAR_AUTHENTICATED,
    TEXT_STYLE_PROJECT,
  } from "./global.js"

  // *** STORES
  import {
    localUserUUID,
    localUserSessionID,
    localUserName,
    // localUserAuthenticated,
    // authenticatedUserInformation,
    globalSettings,
    areaList,
    currentArea,
    currentAreaObject,
    currentTextRoom,
    // currentAudioRoom,
    currentVideoRoom,
    globalUserList,
  } from "./stores.js"
  import { window } from "lodash/_freeGlobal"

  // *** PROPS
  export let params = false

  // *** DOM REFERENCES
  let gameContainer = {}

  // *** VARIABLES
  let activeContentClosed = false
  // let supportStreamClosed = false
  let audioChatActive = false
  let sidebarHidden = false
  // let intentToPickUp = false
  let inAudioZone = false
  let mobileExpanded = false
  let miniImage = false
  let showWelcomeCard = false
  let localPlayers = {}
  let chatMessages = []
  let moveQ = []
  let reconnectionAttempts = 0
  let disconnectionCode = 0
  let currentStreamEvent = false
  let currentStreamUrl = false
  // let supportStreamUrl = false
  let closedAreaCards = []

  // ___ Routing
  let section = false
  let slug = false
  // let sso = false
  // let sig = false
  // let returnSection = false
  // let returnSlug = false

  /// *** CONSTANTS
  // const loadingTimestamp = Date.now()

  $: {
    // ___ Split the url parameter into variables
    const args = get(params, "[*]", "").split("/")
    section = args[0] && args[0].length > 0 ? args[0] : "seed"
    // if (section === "authenticate") {
    //   sso = args[1] && args[1].length > 0 ? args[1] : false
    //   sig = args[2] && args[2].length > 0 ? args[2] : false
    //   returnSection = args[3] && args[3].length > 0 ? args[3] : false
    //   returnSlug = args[4] && args[4].length > 0 ? args[4] : false
    // } else {
    slug = args[1] && args[1].length > 0 ? args[1] : false
    // }
    console.log("SECTION:", section)
    console.log("SLUG:", slug)
  }

  $: {
    if (section === "area" && slug) {
      if ($areaList && Array.isArray($areaList)) {
        const targetArea = $areaList.find(a => a.slug.current === slug)
        if (targetArea) {
          // __ Clear section and slug
          navigate("/")
          // __ Teleport
          if (REVERSE_HEX_MAP[targetArea.color]) {
            teleportTo(REVERSE_HEX_MAP[targetArea.color])
          }
        }
      }
    }
  }
  // ___ Listen for changes to page visibility (ie. tab being out of focus etc..)
  // ___ Fastforward animations when window is refocused
  let deltaJump = 0
  let hiddenTime = 0
  let hidden, visibilityChange

  if (typeof document.hidden !== "undefined") {
    hidden = "hidden"
    visibilityChange = "visibilitychange"
  } else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden"
    visibilityChange = "msvisibilitychange"
  } else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden"
    visibilityChange = "webkitvisibilitychange"
  }

  const handleVisibilityChange = () => {
    if (document[hidden]) {
      hiddenTime = Date.now()
    } else {
      // Number of frames missed (1000ms / 60frames ≈ 16.6666)
      deltaJump = Math.round((Date.now() - hiddenTime) / 16.6666)
    }
  }

  document.addEventListener(visibilityChange, handleVisibilityChange, false)

  // ___ Get data from Sanity CMS
  const graphicsSettings = loadData(QUERY.GRAPHICS_SETTINGS).catch(err => {
    console.log(err)
  })
  const events = loadData(QUERY.EVENTS).catch(err => {
    console.log(err)
  })
  const exhibitions = loadData(QUERY.EXHIBITIONS).catch(err => {
    console.log(err)
  })
  const projects = loadData(QUERY.PROJECTS).catch(err => {
    console.log(err)
  })
  // const audioInstallations = loadData(QUERY.AUDIO_INSTALLATIONS).catch(err => {
  //   console.log(err)
  // })
  // const landMarks = loadData(QUERY.LAND_MARKS).catch(err => {
  //   console.log(err)
  // })
  const users = loadData(QUERY.USERS).catch(err => {
    console.log(err)
  })
  const pages = loadData(QUERY.PAGES).catch(err => {
    console.log(err)
  })
  // const audioRoomNames = loadData(QUERY.AUDIOROOM_NAMES).catch(err => {
  //   console.log(err)
  // })
  const tutorialCard = loadData(QUERY.TUTORIAL_CARD).catch(err => {
    console.log(err)
  })

  // __ Set global user list
  users.then(users => {
    globalUserList.set(users)
    return users
  })

  loadData(QUERY.GLOBAL_SETTINGS)
    .then(gS => {
      console.dir(gS)
      globalSettings.set(gS)
    })
    .catch(err => {
      console.log(err)
    })

  loadData(QUERY.AREAS)
    .then(areas => {
      areaList.set(areas)
    })
    .catch(err => {
      console.log(err)
    })

  let activeStreams = loadData(QUERY.ACTIVE_STREAMS)
    .catch(err => {
      console.log(err)
    })
    .then(activeStreams => {
      currentStreamEvent = activeStreams.mainStreamEvent
      currentStreamUrl = activeStreams.mainStream
      // supportStreamUrl = activeStreams.supportStream
    })

  // __ Listen for changes to the active streams post
  client.listen(QUERY.ACTIVE_STREAMS).subscribe(update => {
    currentStreamUrl = false
    currentStreamEvent = false
    // supportStreamUrl = false
    setTimeout(() => {
      activeStreams = loadData(QUERY.ACTIVE_STREAMS)
        .then(aS => {
          if (aS.mainStream) {
            currentStreamEvent = aS.mainStreamEvent
            currentStreamUrl = aS.mainStream
            // supportStreamUrl = activeStreams.supportStream
            activeContentClosed = false
            // supportStreamClosed = false
          } else {
            currentStreamUrl = false
            currentStreamEvent = false
            // supportStreamUrl = false
          }
        })
        .catch(err => {
          console.log(err)
        })
    }, 1000)
  })

  // ___ Set overarching state of the UI
  const STATE = {
    ERROR: 0,
    READY: 1,
    LOADING: 2,
    DISCONNECTED: 3,
    SETUSERNAME: 4,
  }

  const UI = { state: STATE.LOADING, errorMessage: false }

  const setUIState = (newState, errorMessage = false) => {
    console.log("NEW STATE", newState)
    switch (newState) {
      case STATE.READY:
        UI.state = STATE.READY
        break
      case STATE.LOADING:
        UI.state = STATE.LOADING
        break
      case STATE.DISCONNECTED:
        UI.state = STATE.DISCONNECTED
        break
      case STATE.SETUSERNAME:
        UI.state = STATE.SETUSERNAME
        break
      default:
        UI.state = STATE.ERROR
        UI.errorMessage = errorMessage
    }
  }

  // __ Connect to Colyseus gameserver
  const gameClient = new Colyseus.Client(GAME_SERVER_URL)

  // ___ For animations
  const tweener = new Tweener(1 / 60)

  // PIXI
  let app = {}
  let viewport = {}
  let ticker = {}
  let avatarSpritesheets = {}
  // layers
  let mapLayer = {}
  // let emergentLayer = {}
  let exhibitionLayer = {}
  let audioInstallationLayer = {}
  let playerLayer = {}
  let landMarkLayer = {}
  // misc
  let targetGraphics = {}
  // let cull = {}
  // const cull = new Cull.Simple();

  // const checkAudioProximity = () => {
  //   audioInstallationLayer.children.forEach(a => {
  //     // Get distance between user and audio installation
  //     const dist = Math.sqrt(
  //       Math.pow(a.x - localPlayers[$localUserSessionID].avatar.x, 2) +
  //         Math.pow(a.y - localPlayers[$localUserSessionID].avatar.y, 2)
  //     )
  //     // Check if user is within range of audio installation
  //     if (dist < a.radius) {
  //       inAudioZone = a.slug
  //       if (!a.audio.playing() && !a.noAutoplay) {
  //         a.audio.play()
  //       }
  //       // Set volume proportionally to distance
  //       // Formula to translate ranges:
  //       // NewValue = ((OldValue - OldMin) * NewRange) / OldRange + NewMin;
  //       a.audio.volume(1 - dist / a.radius)
  //     }
  //     if (dist > a.radius) {
  //       if (inAudioZone == a.slug) {
  //         inAudioZone = false
  //       }
  //       if (a.audio.playing()) {
  //         a.audio.pause()
  //         a.audio.volume(0)
  //       }
  //     }
  //   })
  // }

  // __ Game loop
  // __ Called at approximately 60fps by pixi.ticker
  const updatePositions = delta => {
    // Combine delta (lag) and potential time passed since window was in focus
    let deltaRounded = Math.round(delta) + deltaJump
    deltaJump = 0
    // Iterate over all users currently in move queue
    for (let key in moveQ) {
      if (localPlayers[key]) {
        if (moveQ[key].length > 0) {
          if (moveQ[key].length - deltaRounded < 0) {
            // User reached destination while the window was out of focus
            // Move to final step and clear users's move queue
            let step = moveQ[key][moveQ[key].length - 1]
            localPlayers[key].avatar.setAnimation(step.direction)
            localPlayers[key].avatar.x = step.x
            localPlayers[key].avatar.y = step.y
            localPlayers[key].area = step.area
            moveQ[key] = []
            if (key === $localUserSessionID) {
              // checkAudioProximity()
            }
          } else {
            // Get next step, adjusting for delta
            moveQ[key].splice(0, deltaRounded - 1)
            let step = moveQ[key].shift()
            localPlayers[key].avatar.setAnimation(step.direction)
            localPlayers[key].avatar.x = step.x
            localPlayers[key].avatar.y = step.y
            localPlayers[key].area = step.area
            if (key === $localUserSessionID && moveQ[key].length % 30 === 0) {
              // Set current area for users
              currentArea.set(localPlayers[$localUserSessionID].area)
              // Check proximity to audio installations every 30th step
              // checkAudioProximity()
            }
          }
        } else {
          // Destination reached
          if (key === $localUserSessionID) {
            hideTarget()
            // checkAudioProximity()
            // User was walking towards a project
            // if (intentToPickUp) {
            //   pickUpCaseStudy(intentToPickUp)
            // }
          }
          localPlayers[key].avatar.setAnimation("rest")
          delete moveQ[key]
        }
      } else {
        delete moveQ[key]
      }
    }
  }

  // __ Mark path destination
  const showTarget = (x, y) => {
    const graphics = new PIXI.Graphics()
    graphics.beginFill(0xffffff)
    graphics.alpha = 0.5
    graphics.drawCircle(x, y, 10)
    graphics.endFill()
    mapLayer.addChild(graphics)
    targetGraphics = graphics
  }

  const hideTarget = () => {
    mapLayer.removeChild(targetGraphics)
    targetGraphics = {}
  }

  // *** GLOBAL FUNCTIONS
  let teleportTo = () => {}
  let moveTo = () => {}
  let submitChat = () => {}
  // let dropCaseStudy = () => {}
  // let pickUpCaseStudy = () => {}

  const initializeGameWorld = () => {
    // __ Load assets
    graphicsSettings.then(graphicsSettings => {
      // __ Load map
      const mapAsset = get(graphicsSettings, "mapLink.mainImage.asset", false)
      if (mapAsset) {
        // __ Get minimap URL
        miniImage = urlFor(graphicsSettings.mapLink.miniImage.asset)
          .width(400)
          .height(400)
          .quality(100)
          .auto("format")
          .url()
        // __ Load main map
        const mapLoader = new PIXI.Loader()
        const mapUrl = urlFor(mapAsset).url()
        mapLoader.add("map", mapUrl)
        mapLoader.load((loader, resources) => {
          const map = new PIXI.Sprite(resources.map.texture)
          map.width = MAP.WIDTH
          map.height = MAP.HEIGHT
          mapLayer.addChild(map)
        })
      } else {
        setUIState(STATE.ERROR, "Unable to load map")
        throw "Unable to load map"
      }

      // __ Load avatars
      const activeAvatars = get(graphicsSettings, "activeAvatars", false)
      const avatarLoader = new PIXI.Loader()
      if (activeAvatars && activeAvatars.length > 0) {
        activeAvatars.forEach((avatar, index) => {
          const spriteUrl = get(avatar, "spriteJsonURL", false)
          if (spriteUrl) {
            avatarLoader.add(avatar._id, spriteUrl)
          }
        })
      } else {
        setUIState(STATE.ERROR, "Unable to load avatars")
        throw "Unable to load avatars"
      }

      avatarLoader.load((loader, resources) => {
        for (let key of Object.keys(resources)) {
          if (resources[key].extension === "json") {
            avatarSpritesheets[key] = resources[key].spritesheet
          }
        }

        // __ Create player
        const createPlayer = (playerOptions, sessionId) => {
          // __ Create sprites for all motion states
          const sprites = ["rest", "front", "back", "left", "right"].map(ms => {
            console.log("******")
            console.log("playerOptions.avatar", playerOptions.avatar)
            console.log("avatarSpritesheets", avatarSpritesheets)
            console.log(
              "avatarSpritesheets[playerOptions.avatar]",
              avatarSpritesheets[playerOptions.avatar]
            )
            const sprite = new PIXI.AnimatedSprite(
              avatarSpritesheets[playerOptions.avatar].animations[ms]
            )
            sprite.name = ms
            sprite.visible = ms === "rest" ? true : false
            sprite.height = 40
            sprite.width = 40
            sprite.animationSpeed = ms === "rest" ? 0.02 : 0.1
            sprite.play()
            return sprite
          })

          // __ Name graphics (shown on hover)
          const textSprite = new PIXI.Text(
            playerOptions.name,
            playerOptions.authenticated
              ? TEXT_STYLE_AVATAR_AUTHENTICATED
              : TEXT_STYLE_AVATAR
          )
          const txtBG = new PIXI.Sprite(PIXI.Texture.WHITE)
          txtBG.width = textSprite.width + 10
          txtBG.height = textSprite.height + 10
          textSprite.x = 5
          textSprite.y = 5
          const textContainer = new PIXI.Container()
          textContainer.addChild(txtBG, textSprite)
          textContainer.name = "text"

          // __ Add sprites and initial position to container
          const avatar = new PIXI.Container()
          avatar.addChild(...sprites)
          avatar.motionState = "rest"
          avatar.x = playerOptions.x
          avatar.y = playerOptions.y
          avatar.pivot.x = avatar.width / 2
          avatar.pivot.y = avatar.height / 2
          avatar.interactive = true
          avatar.setAnimation = direction => {
            avatar.motionState = direction
            avatar.children.forEach(c => {
              c.visible = c.name == direction || c.name == "text" ? true : false
            })
          }

          const player = {
            avatar: avatar,
            waypoints: [],
            area: playerOptions.area,
            name: playerOptions.name,
            discourseName: playerOptions.discourseName,
            uuid: playerOptions.uuid,
            ip: playerOptions.ip,
            tint: playerOptions.tint,
            connected: playerOptions.connected,
            authenticated: playerOptions.authenticated,
            id: sessionId,
            isSelf: playerOptions.uuid === $localUserUUID,
          }

          const onDown = e => {
            // __ Open profile if accredited user
            if (player.authenticated) {
              // __ Get user from userlist
              const targetUser = $globalUserList.find(
                u => u.username === player.discourseName
              )
              if (targetUser && get(targetUser, "slug.current", false)) {
                navigate("/profiles/" + targetUser.slug.current)
              }
            }
            if (player.uuid != $localUserUUID) {
              e.stopPropagation()
            }
          }

          const onEnter = () => {
            if (player.authenticated) {
              gameContainer.style.cursor = "pointer"
            }
            textContainer.y = 30 - textContainer.height / 2
            textContainer.x = -(textContainer.width / 2) + 30
            avatar.addChild(textContainer)
          }

          const onLeave = () => {
            gameContainer.style.cursor = "crosshair"
            avatar.removeChild(textContainer)
          }

          player.avatar.on("mousedown", onDown)
          player.avatar.on("touchstart", onDown)
          player.avatar.on("mouseover", onEnter)
          player.avatar.on("mouseout", onLeave)

          playerLayer.addChild(player.avatar)

          if (player.isSelf) {
            // __ Follow own avatar in viewport
            viewport.follow(player.avatar, {
              radius: 20,
              acceleration: 400,
            })
            localUserSessionID.set(player.id)
            // __ Uncomment this line to show the accredited user toolkit while developing...
            // localUserAuthenticated.set(true)

            // __ Set cookie if user is successfully authenticated
            // if (player.authenticated) {
            //   Cookies.set("postrational-username", "TEST NAME", { expires: 7 })
            //   localUserName.set("TEST NAME")
            //   // localUserAuthenticated.set(true)
            //   loadData(QUERY.AUTH_USER_INFO, {
            //     username: player.discourseName,
            //   })
            //     .then(info => {
            //       authenticatedUserInformation.set(info)
            //     })
            //     .catch(err => {
            //       console.log(err)
            //     })
            //   // __ Navigate based on URL paramters passed
            //   // __ before going through authenticateion
            //   let returnPath = "/"
            //   returnPath += returnSection ? returnSection : ""
            //   returnPath += returnSlug ? "/" + returnSlug : ""
            //   navigate(returnPath)
            // }
            // __ Loading is done
            setUIState(STATE.READY)
          }

          return player
        }

        // __ Get a random avatar
        // console.log('!!!!!! activeAvatars', activeAvatars)
        // console.log('===> filtered', activeAvatars.filter(a => !a.notRandom))
        const randomAvatar = sample(activeAvatars.filter(a => !a.notRandom))

        let playerObject = {}

        // if (section === "authenticate" && sso && sig) {
        //   playerObject = {
        //     sso: sso,
        //     sig: sig,
        //     uuid: $localUserUUID,
        //     tint: "0xffff00",
        //   }
        // } else {
        playerObject = {
          uuid: $localUserUUID,
          name: $localUserName,
          avatar: randomAvatar._id,
          tint: "0xff0000",
        }
        // }

        // __ Join game room
        gameClient
          .joinOrCreate("game", playerObject)
          .then(gameRoom => {
            // ******
            // PLAYER
            // ******

            // PLAYER => REMOVE
            gameRoom.state.players.onRemove = (player, sessionId) => {
              try {
                if (get(localPlayers[sessionId], "avatar", false)) {
                  // Remove player graphics
                  playerLayer.removeChild(localPlayers[sessionId].avatar)
                  // !!! HACK
                  setTimeout(() => {
                    // Delete player object
                    // console.log('deleting player')
                    delete localPlayers[sessionId]
                    localPlayers = localPlayers
                  }, 500)
                }
              } catch (err) {
                setUIState(STATE.ERROR, err)
                console.dir(err)
              }
            }

            // PLAYER => ADD
            gameRoom.state.players.onAdd = (player, sessionId) => {
              localPlayers[sessionId] = createPlayer(player, sessionId)
              // cull.add(localPlayers[sessionId].avatar);
              // console.dir(cull)
              // PLAYER => CHANGE
              player.onChange = changes => {
                // if ($localUserSessionID === sessionId) {
                // localPlayers[sessionId].carrying = player.carrying
                // __ Carrying ?
                // if (localPlayers[sessionId].carrying && intentToPickUp) {
                //   let g = emergentLayer.children.find(
                //     cs => cs.uuid === player.carrying
                //   )
                //   navigate("/projects/" + g.slug)
                //   intentToPickUp = false
                // }
                // }
                if (player.path.waypoints.length > 0) {
                  // __ Normal movement
                  moveQ[sessionId] = player.path.waypoints
                } else {
                  // __ Teleport
                  localPlayers[sessionId].area = player.area
                  localPlayers[sessionId].avatar.x = player.x
                  localPlayers[sessionId].avatar.y = player.y
                  localPlayers[sessionId].avatar.setAnimation("rest")
                  if ($localUserSessionID === sessionId) {
                    currentArea.set(localPlayers[sessionId].area)
                  }
                }
              }
            }

            // PLAYER => BANNED
            gameRoom.onMessage("banned", message => {
              setUIState(STATE.ERROR, "You have been banned")
            })

            // PLAYER => ILLEGAL MOVE
            gameRoom.onMessage("illegalMove", message => {
              const initialX = localPlayers[$localUserSessionID].avatar.x
              // __ Vibrate avatar
              tweener
                .add(localPlayers[$localUserSessionID].avatar)
                .to(
                  {
                    x: initialX + 10,
                  },
                  0.05
                )
                .to(
                  {
                    x: initialX - 10,
                  },
                  0.1
                )
                .to(
                  {
                    x: initialX,
                  },
                  0.05
                )
              hideTarget()
            })

            // PLAYER => CLICK / TAP
            viewport.on("clicked", e => {
              // __ Cancel current movement
              delete moveQ[$localUserSessionID]
              hideTarget()
              // __ Start new movement
              const targetX = Math.round(e.world.x)
              const targetY = Math.round(e.world.y)
              showTarget(targetX, targetY)
              gameRoom.send("go", {
                x: targetX,
                y: targetY,
                originX: localPlayers[$localUserSessionID].avatar.x,
                originY: localPlayers[$localUserSessionID].avatar.y,
              })
            })

            // Keyboard navigation (ASDW)
            document.addEventListener("keydown", event => {
              console.log(event.key)

              if (
                !moveQ[$localUserSessionID] &&
                ["a", "s", "d", "w"].includes(
                  get(event, "key", "").toLowerCase()
                )
              ) {
                let targetX = 0
                let targetY = 0

                const STEP_SIZE = 50

                switch (event.key) {
                  case "d":
                    targetX =
                      localPlayers[$localUserSessionID].avatar.x + STEP_SIZE
                    targetY = localPlayers[$localUserSessionID].avatar.y
                    break
                  case "a":
                    targetX =
                      localPlayers[$localUserSessionID].avatar.x - STEP_SIZE
                    targetY = localPlayers[$localUserSessionID].avatar.y
                    break
                  case "w":
                    targetX = localPlayers[$localUserSessionID].avatar.x
                    targetY =
                      localPlayers[$localUserSessionID].avatar.y - STEP_SIZE
                    break
                  case "s":
                    targetX = localPlayers[$localUserSessionID].avatar.x
                    targetY =
                      localPlayers[$localUserSessionID].avatar.y + STEP_SIZE
                    break
                  default:
                    targetX = localPlayers[$localUserSessionID].avatar.x
                    targetY = localPlayers[$localUserSessionID].avatar.y
                }

                showTarget(targetX, targetY)

                gameRoom.send("go", {
                  x: targetX,
                  y: targetY,
                  originX: localPlayers[$localUserSessionID].avatar.x,
                  originY: localPlayers[$localUserSessionID].avatar.y,
                })
              }
            })

            // PLAYER => TOUCH END
            viewport.on("touchend", e => {
              // __ Convert screen coordinates to world coordinates
              const world = viewport.toWorld(e.data.global.x, e.data.global.y)
              // __ Cancel current movement
              delete moveQ[$localUserSessionID]
              hideTarget()
              // // __ Start new movement
              const targetX = Math.round(world.x)
              const targetY = Math.round(world.y)
              showTarget(targetX, targetY)
              gameRoom.send("go", {
                x: targetX,
                y: targetY,
                originX: localPlayers[$localUserSessionID].avatar.x,
                originY: localPlayers[$localUserSessionID].avatar.y,
              })
            })

            // PLAYER => TELEPORT
            teleportTo = area => {
              // __ Cancel current movement
              delete moveQ[$localUserSessionID]
              hideTarget()
              gameRoom.send("teleport", {
                area: area,
              })
            }

            // PLAYER => MOVETO
            moveTo = (x, y) => {
              // __ Cancel current movement
              delete moveQ[$localUserSessionID]
              hideTarget()
              showTarget(x, y)
              gameRoom.send("go", {
                x: x,
                y: y,
                originX: localPlayers[$localUserSessionID].avatar.x,
                originY: localPlayers[$localUserSessionID].avatar.y,
              })
            }

            // *******
            // MESSAGE
            // *******

            // MESSAGE => ADD
            gameRoom.state.messages.onAdd = message => {
              chatMessages = [...chatMessages, message]
              if ($localUserUUID == message.uuid) {
                const messageContainerEl =
                  document.querySelector("#message-container")
                if (messageContainerEl) {
                  setTimeout(() => {
                    messageContainerEl.scrollTo({
                      top: messageContainerEl.scrollHeight,
                      left: 0,
                      behavious: "smooth",
                    })
                  }, 200)
                }
              }
            }

            // MESSAGE => REMOVE
            gameRoom.onMessage("nukeMessage", msgIdToRemove => {
              const itemIndex = chatMessages.findIndex(
                m => m.msgId === msgIdToRemove
              )
              chatMessages.splice(itemIndex, 1)
              chatMessages = chatMessages
            })

            // MESSAGE => SUBMIT
            submitChat = event => {
              try {
                gameRoom.send("submitChatMessage", {
                  msgId: nanoid(),
                  uuid: $localUserUUID,
                  name: localPlayers[$localUserSessionID].name,
                  username: localPlayers[$localUserSessionID].discourseName,
                  authenticated:
                    localPlayers[$localUserSessionID].authenticated,
                  text: event.detail.text,
                  room: $currentTextRoom,
                  tint: localPlayers[$localUserSessionID].tint,
                })
              } catch (err) {
                setUIState(STATE.ERROR, err)
                console.dir(err)
              }
            }

            // ************************
            // CASE STUDIES => DISABLED!
            // ************************

            // dropCaseStudy = () => {
            //   gameRoom.send("dropCaseStudy", {
            //     uuid: localPlayers[$localUserSessionID].carrying,
            //   })
            // }

            // pickUpCaseStudy = uuid => {
            //   gameRoom.send("pickUpCaseStudy", {
            //     uuid: uuid,
            //   })
            // }

            // __ Create Case Study
            // const createCaseStudy = (caseStudy, animate) => {
            //   const container = new PIXI.Container()
            //   // __ Hide if currently in a user's inventory
            //   container.visible = caseStudy.carriedBy === "" ? true : false
            //   container.uuid = caseStudy.uuid
            //   container.caseStudyId = caseStudy.caseStudyId
            //   container.name = caseStudy.name
            //   container.category = caseStudy.category || "none"
            //   container.slug = caseStudy.slug
            //   container.interactive = true
            //   container.tint = caseStudy.tint

            //   const graphics = new PIXI.Graphics()
            //   graphics.beginFill(caseStudy.tint)
            //   graphics.drawRect(0, 0, 15, 15)
            //   graphics.endFill()

            //   // __ Name graphics (shown on hover)
            //   const textSprite = new PIXI.Text(
            //     caseStudy.name,
            //     TEXT_STYLE_CASE_STUDY
            //   )
            //   const txtBG = new PIXI.Sprite(PIXI.Texture.WHITE)
            //   txtBG.tint = 0x000000
            //   txtBG.width = textSprite.width + 10
            //   txtBG.height = textSprite.height + 10
            //   textSprite.x = 5
            //   textSprite.y = 5
            //   const textContainer = new PIXI.Container()
            //   textContainer.addChild(txtBG, textSprite)
            //   textContainer.name = "text"

            //   container.x = caseStudy.x
            //   container.y = animate ? 0 : caseStudy.y

            //   container.addChild(graphics)

            //   // __ Animate in with bounce
            //   if (animate) {
            //     tweener
            //       .add(container)
            //       .to({ y: caseStudy.y }, 3, Tweener.ease.bounceOut)
            //   }

            //   const onDown = e => {
            //     // __ Make user drop case study if carrying, to allow picking up new one
            //     if (
            //       localPlayers[$localUserSessionID].carrying &&
            //       localPlayers[$localUserSessionID].carrying.length > 0
            //     ) {
            //       gameRoom.send("dropCaseStudy", {
            //         uuid: localPlayers[$localUserSessionID].carrying,
            //       })
            //     }

            //     // __ Move towards clicked case study
            //     // __ and indicate that it should be picked up once reached
            //     const g = emergentLayer.children.find(
            //       cs => cs.uuid === caseStudy.uuid
            //     )
            //     if (g) {
            //       intentToPickUp = caseStudy.uuid
            //       gameRoom.send("go", {
            //         x: g.x,
            //         y: g.y,
            //         originX: localPlayers[$localUserSessionID].avatar.x,
            //         originY: localPlayers[$localUserSessionID].avatar.y,
            //       })
            //     }
            //     e.stopPropagation()
            //   }

            //   const onEnter = () => {
            //     gameContainer.style.cursor = "pointer"
            //     textContainer.y =
            //       container.y + container.height / 2 - textContainer.height / 2
            //     textContainer.x =
            //       container.x - textContainer.width / 2 + container.width / 2
            //     playerLayer.addChild(textContainer)
            //   }

            //   const onLeave = e => {
            //     gameContainer.style.cursor = "crosshair"
            //     playerLayer.removeChild(textContainer)
            //   }

            //   container.on("mousedown", onDown)
            //   container.on("touchstart", onDown)
            //   container.on("mouseover", onEnter)
            //   container.on("mouseout", onLeave)

            //   emergentLayer.addChild(container)
            // }

            // CASE STUDY => ADD
            // gameRoom.state.projects.onAdd = (caseStudy, sessionId) => {
            //   // console.log('loadingTimestamp', loadingTimestamp)
            //   // console.log('caseStudy.timestamp', caseStudy.timestamp)
            //   if (get(caseStudy, "timestamp", Date.now()) > loadingTimestamp) {
            //     createCaseStudy(caseStudy, true)
            //   } else {
            //     createCaseStudy(caseStudy, false)
            //   }
            //   // CASE STUDY => CHANGE
            //   caseStudy.onChange = changes => {
            //     const g = emergentLayer.children.find(
            //       cs => cs.uuid === caseStudy.uuid
            //     )
            //     if (g) {
            //       // __ Darken color one step
            //       g.children[0].tint = TINTMAP[caseStudy.age - 1]
            //       // __ Update position if not currently in a user's inventory
            //       if (caseStudy.carriedBy === "") {
            //         g.x = caseStudy.x
            //         g.y = caseStudy.y
            //         g.visible = true
            //       } else {
            //         g.visible = false
            //       }
            //     }
            //   }
            // }

            // CASE STUDY => REMOVE
            // gameRoom.state.projects.onRemove = (caseStudy, sessionId) => {
            //   // !! TODO: PROPERLY REMOVE CASE STUDY
            // }

            // ******************************
            // CLIENT LEFT / WAS DISCONNECTED
            // ******************************
            gameRoom.onLeave(code => {
              const exitMsg = "Disconnected from server. Code: " + code
              // console.log(exitMsg);
              // __ Show notification of disconnection
              setUIState(STATE.DISCONNECTED)
              disconnectionCode = code
              reconnectionAttempts = 1
              // TODO: Try to reconnect
              const reconnect = i => {
                console.log(
                  "Trying to reconnect user:",
                  $localUserSessionID,
                  "....",
                  i
                )
                gameClient
                  .reconnect("game", $localUserSessionID)
                  .then(room => {
                    // __ Successfully reconnected
                    setUIState(STATE.READY)
                  })
                  .catch(e => {
                    console.error("join error", e)
                  })
                //   setInterval(() => {
                //   reconnectionAttempts++
                // }, 5000)
              }
              reconnect(1)
            })

            // ************************
            // GENERAL ERROR HANDLING
            // ************************
            gameRoom.onError((code, message) => {
              setUIState(STATE.ERROR, message)
              console.error("Gameserver error:", message)
            })
          })
          .catch(e => {
            console.dir(e)
            if (e.code == 4215) {
              setUIState(STATE.ERROR, "You have been banned")
            } else {
              setUIState(STATE.ERROR, "FAILED TO CONNECT TO GAMESERVER")
            }
          })
      })

      // __ Add exhibition (static) projects
      projects.then(projects => {
        projects
          .filter(cs => cs._type === "project")
          .forEach((cs, i) => {
            const spriteUrl = get(cs, "spriteLink.spriteJsonURL", "")
            const spriteId = "project-" + cs._id
            const csLoader = new PIXI.Loader()
            csLoader.add(spriteId, spriteUrl).load((loader, resources) => {
              console.log
              const frames = new PIXI.AnimatedSprite(
                resources[spriteId].spritesheet.animations["frames"]
              )
              frames.animationSpeed = 0.02
              frames.play()

              // __ Name graphics (shown on hover)
              const textSprite = new PIXI.Text(cs.title, TEXT_STYLE_PROJECT)
              const txtBG = new PIXI.Sprite(PIXI.Texture.WHITE)
              txtBG.tint = 0x000000
              txtBG.width = textSprite.width + 10
              txtBG.height = textSprite.height + 10
              textSprite.x = 5
              textSprite.y = 5
              const textContainer = new PIXI.Container()
              textContainer.addChild(txtBG, textSprite)
              textContainer.name = "text"

              const projectLocation = new PIXI.Container()
              projectLocation.addChild(frames)
              projectLocation.x = cs.x
              projectLocation.y = cs.y
              projectLocation.pivot.x = projectLocation.width / 2
              projectLocation.pivot.y = projectLocation.height / 2
              projectLocation.title = cs.title
              projectLocation.interactive = true

              const onDown = e => {
                navigate("/projects/" + get(cs, "slug.current", false))
                e.stopPropagation()
              }

              const onEnter = e => {
                gameContainer.style.cursor = "pointer"
                textContainer.y =
                  projectLocation.height / 2 - textContainer.height / 2
                textContainer.x =
                  -(textContainer.width / 2) + projectLocation.width / 2
                projectLocation.addChild(textContainer)
              }

              const onLeave = e => {
                gameContainer.style.cursor = "crosshair"
                projectLocation.removeChild(textContainer)
              }

              projectLocation.on("mousedown", onDown)
              projectLocation.on("touchstart", onDown)
              projectLocation.on("mouseover", onEnter)
              projectLocation.on("mouseout", onLeave)

              exhibitionLayer.addChild(projectLocation)
            })
          })
      })

      // __ Add landmarks
      // landMarks.then(landMarks => {
      //   landMarks.forEach((lm, i) => {
      //     const spriteUrl = get(lm, "spriteJsonURL", "")
      //     const spriteId = "landMark-" + lm._id
      //     const lmLoader = new PIXI.Loader()

      //     lmLoader.add(spriteId, spriteUrl).load((loader, resources) => {
      //       const frames = new PIXI.AnimatedSprite(
      //         resources[spriteId].spritesheet.animations["frames"]
      //       )
      //       // frames.visible = true
      //       frames.animationSpeed = 0.02
      //       frames.play()

      //       const landMarkLocation = new PIXI.Container()
      //       landMarkLocation.addChild(frames)
      //       landMarkLocation.x = lm.x
      //       landMarkLocation.y = lm.y
      //       landMarkLocation.pivot.x = landMarkLocation.width / 2
      //       landMarkLocation.pivot.y = landMarkLocation.height / 2
      //       landMarkLayer.addChild(landMarkLocation)
      //     })
      //   })
      // })
    })
  }

  const getResponsiveWidth = () =>
    window.matchMedia("(max-width: 800px)").matches || sidebarHidden
      ? window.innerWidth
      : window.innerWidth - 400

  onMount(async () => {
    // ___ Set Global scale mode to hard edges
    PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST

    // ___ Create Pixi App
    app = new PIXI.Application({
      width: MAP.WIDTH,
      height: MAP.HEIGHT,
      resolution: 1,
    })

    // __ Create Pixi Viewport
    viewport = new Viewport({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      worldWidth: MAP.WIDTH,
      worldHeight: MAP.HEIGHT,
      interaction: app.renderer.plugins.interaction,
    })
    app.stage.addChild(viewport)

    // ___ Create and add layers
    // (1) => Map
    // (2) => Audio Installations
    // (3) => Exhibition/static case studies
    // (4) => Emergent/mobil case studies
    // (5) => Players
    // (6) => Landmarks
    mapLayer = new PIXI.Container()
    // emergentLayer = new PIXI.Container()
    exhibitionLayer = new PIXI.Container()
    // audioInstallationLayer = new PIXI.Container()
    playerLayer = new PIXI.Container()
    // landMarkLayer = new PIXI.Container()
    viewport.addChild(mapLayer)
    // viewport.addChild(audioInstallationLayer)
    viewport.addChild(exhibitionLayer)
    // viewport.addChild(emergentLayer)
    viewport.addChild(playerLayer)
    // viewport.addChild(landMarkLayer)
    // viewport.drag()

    // ___ Start Pixi ticker
    ticker = PIXI.Ticker.shared
    ticker.start()
    ticker.add(updatePositions)

    // __ Add pixi view to DOM
    gameContainer.appendChild(app.view)

    window.onresize = () => {
      const responsiveWidth = getResponsiveWidth()
      viewport.resize(responsiveWidth, window.innerHeight)
      app.renderer.resize(responsiveWidth, window.innerHeight)
    }
    window.dispatchEvent(new Event("resize"))

    // ___ Give the local user a UUID
    localUserUUID.set(nanoid())

    // __ Redirect to authentication if user is marked as logged in
    const usernameCookie = Cookies.get("postrational-username")
    console.log("usernameCookie", usernameCookie)
    if (!usernameCookie) {
      // ___ Prompt user to enter name
      setUIState(STATE.SETUSERNAME)
    } else {
      // ___ Set username from cookie
      localUserName.set(usernameCookie)
      // ___ Show welcome card if user has not visited in last 7 days
      showWelcomeCard = Cookies.get("postrational-visitor") ? false : true
      // showWelcomeCard = true
      Cookies.set("postrational-visitor", "true", { expires: 7 })
      // __ Start the game...
      initializeGameWorld()
    }
  })
</script>

<!-- <MetaData /> -->
<!-- Show default if not in special section -->
{#if !["projects", "profiles", "profiles", "events", "pages"].includes(section) && !inAudioZone}
  <MetaData />
{/if}

{#if $globalSettings && $globalSettings.title}
  <h1 aria-hidden="true">{$globalSettings.title}</h1>
{/if}

<!-- WELCOME / TUTORIAL -->
{#if UI.state != STATE.LOADING && showWelcomeCard}
  {#await tutorialCard then tutorialCard}
    <div class="tutorial-wrap-outer" transition:fade>
      <Tutorial card={tutorialCard} bind:showWelcomeCard />
      <div
        class="background-hittable"
        aria-label="Close tutorial"
        role="button"
        tabindex="0"
        on:click={e => {
          showWelcomeCard = false
        }}
      />
    </div>
  {/await}
{/if}

<!-- GAME WORLD -->
<div
  class="game"
  class:disabled={UI.state == STATE.DISCONNECTED}
  class:expanded={sidebarHidden}
  bind:this={gameContainer}
/>

<!-- SIDEBAR -->
<!-- Show on desktop only -->
<MediaQuery query="(min-width: 800px)" let:matches>
  {#if matches}
    {#if localPlayers[$localUserSessionID]}
      {#if !sidebarHidden}
        <div
          class="hide-button"
          aria-label="hide sidebar"
          role="button"
          in:scale={{ delay: 500 }}
          on:click={() => {
            sidebarHidden = !sidebarHidden
            window.dispatchEvent(new Event("resize"))
          }}
        >
          »
        </div>
      {/if}
      <aside
        class="sidebar"
        use:links
        aria-label="Show sidebar"
        role="button"
        class:hidden={sidebarHidden}
        on:click={() => {
          if (sidebarHidden) {
            sidebarHidden = false
            window.dispatchEvent(new Event("resize"))
          }
        }}
      >
        <!-- <a class="hidden-link" aria-label="Skip to the Menu">Skip to the Menu</a>   -->

        <!-- MINIMAP -->
        <div class="clock" aria-label="Clock">
          <Clock />
        </div>
        {#if LINK_OUT}
          <div class="link-to-ac">
            <a
              href={LINK_OUT}
              target="_blank"
              aria-label={LINK_OUT_TEXT + ", open in new tab."}
              >{LINK_OUT_TEXT}</a
            >
          </div>
        {/if}
        <div class="minimap">
          <MiniMap {miniImage} player={localPlayers[$localUserSessionID]} />
        </div>
        <div class="middle-section">
          <div class="top-area">
            <!-- CALENDAR -->
            {#await events then events}
              {#await exhibitions then exhibitions}
                <EventList
                  {events}
                  {exhibitions}
                  showArchived={get($globalSettings, "showArchived", false)}
                />
              {/await}
            {/await}
          </div>
          <div class="bottom-area">
            <!-- {#if section == 'seminar'} -->
            <!-- SEMINAR -->
            <!-- <Seminar {slug} /> -->
            <!-- {:else if section == 'messages'} -->
            <!-- MESSAGES -->
            <!-- <Messaging {slug} />
            {:else} -->
            <!-- CHAT -->
            {#each TEXT_ROOMS as TR}
              {#if $currentTextRoom === TR}
                <Chat
                  chatMessages={chatMessages.filter(
                    m => m.room === TR || m.directed
                  )}
                  currentRoom={TR}
                />
              {/if}
            {/each}
            <!-- {/if} -->
            <!-- TOOLBAR-->
            <div class="toolbar">
              <ToolBar
                {section}
                on:submit={submitChat}
                on:teleport={e => {
                  // __ Cancel current movement
                  delete moveQ[$localUserSessionID]
                  hideTarget()
                  teleportTo($currentArea === 5 ? "green" : "blue")
                }}
              />
            </div>
          </div>
        </div>
        <!-- MENUBAR -->
        <div class="menu">
          <Menu
            on:username={e => {
              Cookies.remove("postrational-username")
              window.location = "/"
            }}
          />
        </div>
      </aside>
    {/if}
  {/if}
</MediaQuery>

<!-- MAIN CONTENT -->
<main class="main-content-slot" class:pushed={sidebarHidden}>
  <!-- INFORMATION BOX -->
  {#if get($currentAreaObject, "informationCard", false) && !closedAreaCards.includes($currentAreaObject.areaIndex)}
    <div class="content-item active" transition:fly={{ y: -200 }}>
      <div
        class="close"
        role="button"
        aria-label="Close information card"
        tabindex="0"
        on:click={e => {
          closedAreaCards.push($currentAreaObject.areaIndex)
          closedAreaCards = closedAreaCards
        }}
      >
        <svg
          role="presentation"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28.9 11.1C28.6 10.8 28.2 10.8 27.9 11.1L20 19L12.1 11.1C11.8 10.8 11.4 10.8 11.1 11.1C10.8 11.4 10.8 11.8 11.1 12.1L19 20L11.1 27.9C10.8 28.2 10.8 28.6 11.1 28.9C11.4 29.2 11.8 29.2 12.1 28.9L20 21L27.9 28.9C28.2 29.2 28.6 29.2 28.9 28.9C29.2 28.6 29.2 28.2 28.9 27.9L21 20L28.9 12.1C29.2 11.8 29.2 11.4 28.9 11.1Z"
          />
        </svg>
      </div>
      <Card card={$currentAreaObject.informationCard} />
    </div>
  {/if}

  <!-- AUDIOZONE -->
  <!-- {#if inAudioZone}
    <div
      class="content-item active"
      aria-modal="true"
      role="dialog"
      transition:fly={{ y: -200 }}
    >
      {#await audioInstallations then audioInstallations}
        <AudioInstallationSingle
          {audioInstallationLayer}
          audioInstallation={audioInstallations.find(
            aI => aI.slug.current === inAudioZone
          )}
        />
      {/await}
    </div>
  {/if} -->

  <!-- LIVE -->
  {#await activeStreams then activeStreams}
    <!-- MAIN AREA -->
    {#if $currentVideoRoom == "main" && currentStreamUrl && !activeContentClosed && $localUserName}
      <div
        aria-label="The following item is a youtube embed of the livestream of our festival"
      />
      <div
        class="content-item active"
        aria-label="Livestream"
        transition:fly={{ y: -200 }}
      >
        <LiveSingle event={currentStreamEvent} url={currentStreamUrl} />
      </div>
    {/if}
  {/await}

  <!-- TEXT CONTENT -->
  {#if ["projects", "profiles", "profiles", "events", "pages"].includes(section)}
    <div
      class="content-item passive"
      aria-modal="true"
      role="dialog"
      class:pushed={!activeContentClosed}
      use:links
      transition:fly={{ y: 200, duration: 400, easing: quartOut }}
    >
      <a
        class="close"
        role="button"
        aria-label={slug ? `Close ${slug}` : `Close section`}
        href="/"
      >
        <svg
          role="presentation"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28.9 11.1C28.6 10.8 28.2 10.8 27.9 11.1L20 19L12.1 11.1C11.8 10.8 11.4 10.8 11.1 11.1C10.8 11.4 10.8 11.8 11.1 12.1L19 20L11.1 27.9C10.8 28.2 10.8 28.6 11.1 28.9C11.4 29.2 11.8 29.2 12.1 28.9L20 21L27.9 28.9C28.2 29.2 28.6 29.2 28.9 28.9C29.2 28.6 29.2 28.2 28.9 27.9L21 20L28.9 12.1C29.2 11.8 29.2 11.4 28.9 11.1Z"
          />
        </svg>
      </a>
      <!-- CASE STUDIES -->
      {#await projects then projects}
        {#if section === "projects"}
          {#if slug}
            <!-- SINGLE PROJECT-->
            <ProjectSingle
              project={projects.find(cs => cs.slug.current === slug)}
              on:goToProject={e => {
                console.dir(e)
                moveTo(e.detail.x, e.detail.y)
              }}
            />
          {:else}
            <!-- LIST PROJECT -->
            <ProjectList {projects} />
          {/if}
        {/if}
      {/await}
      <!-- USERS -->
      {#await users then users}
        {#if section == "profiles" && slug}
          <!-- SINGLE PROFILE -->
          <UserProfileSingle
            user={users.find(u => get(u, "slug.current", "") === slug)}
          />
        {/if}
      {/await}
      <!-- EVENTS -->
      {#await events then events}
        {#await exhibitions then exhibitions}
          {#if section === "events"}
            {#if slug}
              <!-- SINGLE EVENT -->
              <EventSingle
                event={events.find(ev => ev.slug.current === slug)}
              />
            {:else}
              <!-- LIST EVENTS -->
              <EventListFull {events} {exhibitions} />
            {/if}
          {/if}
        {/await}
      {/await}
      <!-- PAGES -->
      {#await pages then pages}
        {#if section == "pages" && slug}
          <!-- SINGLE PAGE -->
          <PageSingle
            page={pages.find(p => get(p, "slug.current", "") === slug)}
          />
        {/if}
      {/await}
    </div>
  {/if}
</main>

<!-- MOBILE -->
<MediaQuery query="(max-width: 800px)" let:matches>
  {#if matches}
    <Clock />

    {#if LINK_OUT}
      <div class="link-to-ac">
        <a
          href={LINK_OUT}
          target="_blank"
          aria-label={LINK_OUT_TEXT + ", open in new tab."}>{LINK_OUT_TEXT}</a
        >
      </div>
    {/if}

    {#if localPlayers[$localUserSessionID]}
      <!-- MOBILE CALENDAR-->
      <div class="mobile-calendar" use:links>
        {#await events then events}
          <EventListSliderMobile {events} />
        {/await}
      </div>
      <!-- MOBILE TOOLKIT -->
      {#if !audioChatActive}
        <div
          class="mobile-toolkit"
          use:links
          class:expanded={mobileExpanded}
          aria-label="Show toolkit"
          aria-hidden="true"
          role="button"
          tabindex="0"
          on:click={e => {
            if (
              (!mobileExpanded && e.target.nodeName == "INPUT") ||
              e.target.classList.contains("toolbar-item")
            ) {
              mobileExpanded = true
            }
          }}
        >
          {#if mobileExpanded}
            <div
              class="close"
              role="button"
              aria-label="Close toolkit"
              aria-hidden="true"
              tabindex="0"
              on:click={e => {
                mobileExpanded = false
                e.stopPropagation()
                navigate("/")
              }}
            >
              <svg
                role="presentation"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28.9 11.1C28.6 10.8 28.2 10.8 27.9 11.1L20 19L12.1 11.1C11.8 10.8 11.4 10.8 11.1 11.1C10.8 11.4 10.8 11.8 11.1 12.1L19 20L11.1 27.9C10.8 28.2 10.8 28.6 11.1 28.9C11.4 29.2 11.8 29.2 12.1 28.9L20 21L27.9 28.9C28.2 29.2 28.6 29.2 28.9 28.9C29.2 28.6 29.2 28.2 28.9 27.9L21 20L28.9 12.1C29.2 11.8 29.2 11.4 28.9 11.1Z"
                />
              </svg>
            </div>
          {/if}
          <!-- CHAT -->
          {#each TEXT_ROOMS as TR}
            {#if $currentTextRoom === TR}
              <Chat
                chatMessages={chatMessages.filter(
                  m => m.room === TR || m.directed
                )}
                currentRoom={TR}
                mobile={true}
                {mobileExpanded}
              />
            {/if}
          {/each}
          <!-- {/if} -->
          <!-- TOOLBAR-->
          <div class="toolbar">
            <ToolBar
              {section}
              mobile={true}
              {mobileExpanded}
              on:submit={submitChat}
              on:teleport={e => {
                if (localPlayers[$localUserSessionID].area === 5) {
                  teleportTo("green")
                } else {
                  teleportTo("blue")
                }
              }}
            />
          </div>
        </div>
      {/if}
      <!-- MOBILE MENU-->
      <div class="mobile-menu" use:links>
        <Menu />
      </div>
    {/if}
  {/if}
</MediaQuery>

<!-- AUDIOCHAT BOX  -->
{#if $globalSettings.zoomLink && $localUserName}
  <div class="audiochat-box">
    <div class="message">
      {$globalSettings.zoomText}
    </div>
    <a
      href={$globalSettings.zoomLink}
      target="_blank"
      class="mob-message"
      aria-label="Join Zoom Louge"
    >
      Join
      <svg
        role="presentation"
        width="23"
        height="20"
        viewBox="0 0 23 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.3672 11.4041C8.52696 11.4041 9.49643 11.019 10.2756 10.2489C11.0549 9.47872 11.4445 8.51378 11.4445 7.35402C11.4445 6.19426 11.0549 5.22932 10.2756 4.45917C9.49643 3.68902 8.52696 3.30395 7.3672 3.30395C6.20745 3.30395 5.24251 3.68902 4.47235 4.45917C3.7022 5.22932 3.31713 6.19426 3.31713 7.35402C3.31713 8.51378 3.7022 9.47872 4.47235 10.2489C5.24251 11.019 6.20745 11.4041 7.3672 11.4041ZM8.53602 12.5185H6.22557C5.42824 12.5185 4.67622 12.6635 3.96949 12.9534C3.26277 13.2615 2.64665 13.6783 2.12114 14.2038C1.59562 14.7293 1.17884 15.3454 0.87078 16.0522C0.56272 16.7589 0.408691 17.5109 0.408691 18.3082V19.4771H14.3257V18.3082C14.3257 17.5109 14.1807 16.7589 13.8908 16.0522C13.5827 15.3454 13.166 14.7293 12.6405 14.2038C12.1149 13.6783 11.4988 13.2615 10.7921 12.9534C10.0854 12.6635 9.33335 12.5185 8.53602 12.5185ZM19.4087 0.477051L17.7506 2.13513C18.5117 2.89622 19.1006 3.77056 19.5174 4.75817C19.9342 5.74577 20.1426 6.7832 20.1426 7.87047C20.1426 8.95774 19.9342 9.99517 19.5174 10.9828C19.1006 11.9704 18.5117 12.8447 17.7506 13.6058L19.4087 15.2639C20.3872 14.2672 21.1393 13.1347 21.6648 11.8662C22.1903 10.5977 22.453 9.2658 22.453 7.87047C22.453 6.47514 22.1903 5.14325 21.6648 3.87476C21.1393 2.60628 20.3872 1.47372 19.4087 0.477051ZM16.1197 3.76603L14.4616 5.42412C14.8059 5.7503 15.0642 6.12178 15.2363 6.53857C15.4085 6.95536 15.4945 7.39932 15.4945 7.87047C15.4945 8.34162 15.4085 8.78558 15.2363 9.20237C15.0642 9.61916 14.8059 9.99064 14.4616 10.3168L16.1197 11.9749C16.6633 11.4313 17.0801 10.8061 17.3701 10.0994C17.66 9.39264 17.805 8.64968 17.805 7.87047C17.805 7.09126 17.66 6.3483 17.3701 5.64157C17.0801 4.93484 16.6633 4.30967 16.1197 3.76603Z"
        />
      </svg>
    </a>
    <a
      href={$globalSettings.zoomLink}
      aria-label="Join Zoom Lounge"
      target="_blank"
      class="button"
    >
      Join
    </a>
  </div>
{/if}

<!-- LOADING -->
{#if UI.state == STATE.LOADING}
  <LoadingScreen />
{/if}

<!-- ERROR -->
{#if UI.state == STATE.ERROR}
  <Error message={UI.errorMessage} />
{/if}

<!-- DISCONNECTED -->
{#if UI.state == STATE.DISCONNECTED}
  <Reconnection {reconnectionAttempts} {disconnectionCode} />
{/if}

<!-- USERNAME DIALOG -->
{#if UI.state == STATE.SETUSERNAME}
  <UsernameDialog
    on:username={e => {
      Cookies.set("postrational-username", e.detail.username, { expires: 7 })
      window.location = "/"
    }}
  />
{/if}

<style lang="scss">html {
  height: -webkit-fill-available; }

* {
  box-sizing: border-box;
  font-family: "WorkSans", sans-serif; }

.hidden-link {
  opacity: 0;
  transform: scale(1.2);
  position: absolute;
  right: 8px;
  top: 8px;
  background-color: #f0f0f0;
  z-index: 10000;
  padding: 1rem;
  border: 1px solid black; }
  .hidden-link:focus {
    opacity: 1; }

.inventory {
  position: fixed;
  width: auto;
  max-width: 50vw;
  background: #f0f0f0;
  height: auto;
  line-height: 1.4em;
  text-align: center;
  bottom: 12px;
  left: 12px;
  padding: 12px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  padding-left: 18px;
  padding-right: 18px;
  user-select: none; }
  @media (max-width: 800px) {
    .inventory {
      bottom: 60px;
      left: 12px;
      width: calc(100vw - 24px);
      max-width: calc(100vw - 24px);
      z-index: 10; } }

.audiochat-box {
  position: fixed;
  width: auto;
  background: #f0f0f0;
  height: 41px;
  text-align: center;
  top: 12px;
  left: 12px;
  padding: 18px;
  border-radius: 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  user-select: none; }
  @media (max-width: 800px) {
    .audiochat-box {
      top: unset;
      bottom: calc(25vh + 60px);
      left: unset;
      right: 12px;
      max-width: calc(100vw - 20px); } }
  .audiochat-box .message {
    margin-right: 12px; }
    @media (max-width: 800px) {
      .audiochat-box .message {
        display: none; } }
  .audiochat-box .mob-message {
    display: none;
    font-size: 14px;
    align-items: center; }
    .audiochat-box .mob-message svg {
      margin-left: 12px;
      fill: black; }
    @media (max-width: 800px) {
      .audiochat-box .mob-message {
        display: flex; } }
  .audiochat-box .button {
    text-transform: uppercase;
    font-weight: bold;
    padding: 4px 18px 2px 18px;
    display: inline-table;
    border: 1px solid #202020;
    color: #202020;
    font-size: 14px;
    border-radius: 20px;
    text-align: center;
    text-decoration: none !important; }
    .audiochat-box .button:hover {
      border: 1px solid #c5c5c5;
      background: black;
      color: #f0f0f0;
      cursor: pointer; }
    @media (max-width: 800px) {
      .audiochat-box .button {
        display: none; } }

.game {
  width: calc(100vw - 400px);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  opacity: 1;
  transition: opacity 1s ease-out; }
  @media (max-width: 800px) {
    .game {
      width: 100vw;
      right: 0; } }
  .game.expanded {
    width: 100vw; }
  .game.disabled {
    opacity: 0.3;
    pointer-events: none; }

.hide-button {
  font-family: "IBM Plex Sans", sans-serif;
  position: fixed;
  top: 12px;
  right: 410px;
  width: 40px;
  height: 40px;
  line-height: 36px;
  font-size: 22px;
  text-align: center;
  border-radius: 20px;
  color: #999999;
  background: #f0f0f0;
  user-select: none;
  cursor: pointer;
  transition: background 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 1000; }
  .hide-button:hover {
    background: #c5c5c5; }
  @media (max-width: 800px) {
    .hide-button {
      display: none; } }

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 460px;
  min-height: 100vh;
  height: -webkit-fill-available;
  padding: 0;
  margin: 0;
  overflow: hidden;
  z-index: 100;
  transform: translateX(0);
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1); }
  @media (max-width: 800px) {
    .sidebar {
      width: 100vw; } }
  .sidebar .minimap {
    background: black;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 800px) {
      .sidebar .minimap {
        display: none; } }
  .sidebar .middle-section {
    height: calc(100% - 240px); }
    .sidebar .middle-section .top-area {
      position: relative;
      height: 50%;
      width: 100%;
      overflow: hidden; }
    .sidebar .middle-section .bottom-area {
      background: black;
      height: calc(50% - 50px);
      -ms-overflow-style: none; }
      .sidebar .middle-section .bottom-area::-webkit-scrollbar {
        display: none; }
    .sidebar .middle-section .toolbar {
      width: 100%;
      height: 50px;
      z-index: 1000;
      background: black; }
    @media (max-width: 800px) {
      .sidebar .middle-section {
        display: none; } }
  .sidebar .menu {
    height: 40px; }
  .sidebar.hidden {
    transform: translateX(360px);
    cursor: pointer; }

.main-content-slot {
  position: absolute;
  top: 0;
  right: calc(460px + 12px);
  width: 500px;
  max-width: calc(100vw - (460px + 24px));
  max-height: 100vh;
  z-index: 100;
  overflow-y: auto;
  font-size: 16px;
  color: black;
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  -ms-overflow-style: none;
  transition: transform 0.3s ease-out; }
  .main-content-slot.pushed {
    transform: translatex(360px); }
  .main-content-slot::-webkit-scrollbar {
    display: none; }
  @media (max-width: 800px) {
    .main-content-slot {
      position: fixed;
      bottom: unset;
      top: 80px;
      right: unset;
      left: 0;
      max-width: unset;
      width: 100vw;
      max-height: calc(100% - 130px); } }
  .main-content-slot .content-item {
    width: 100%;
    background: #f0f0f0;
    z-index: 100;
    font-size: 16px;
    color: black;
    position: relative;
    margin-bottom: 12px;
    margin-top: 12px;
    -ms-overflow-style: none;
    transition: transform 0.3s ease-out; }
    .main-content-slot .content-item::-webkit-scrollbar {
      display: none; }
    @media (max-width: 800px) {
      .main-content-slot .content-item {
        margin-bottom: 0;
        margin-top: 0; }
        .main-content-slot .content-item.passive {
          min-height: 100vh; } }
    .main-content-slot .content-item .close {
      margin-bottom: 20px;
      position: absolute;
      top: 2px;
      right: 6px;
      fill: #999999;
      cursor: pointer;
      text-decoration: none;
      transition: color 0.3s cubic-bezier(0.23, 1, 0.32, 1);
      z-index: 5; }
      .main-content-slot .content-item .close:hover {
        color: #555555; }

.mobile-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 1000;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); }

.mobile-toolkit {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 75%, rgba(0, 0, 0, 0) 100%);
  position: fixed;
  bottom: 50px;
  left: 0;
  width: 100%;
  height: 25vh;
  z-index: 10;
  pointer-events: none;
  transition: height 250ms cubic-bezier(0.23, 1, 0.32, 1); }
  .mobile-toolkit .toolbar {
    height: 40px;
    padding-bottom: 6px;
    pointer-events: all; }
  .mobile-toolkit.expanded {
    transition: height 250ms cubic-bezier(0.23, 1, 0.32, 1);
    pointer-events: all;
    background: rgba(0, 0, 0, 0.8);
    height: 50%;
    box-shadow: 0 0 15px 15px rgba(0, 0, 0, 0.8); }
    .mobile-toolkit.expanded .toolbar {
      background: transparent; }
  .mobile-toolkit .close {
    position: fixed;
    bottom: calc(50% + 15px);
    right: 12px;
    font-size: 38px;
    fill: #999999;
    color: #999999;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 10000; }
    .mobile-toolkit .close:hover {
      fill: #555555; }

.mobile-calendar {
  position: fixed;
  background: #f0f0f0;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 1000;
  overflow-x: auto;
  -ms-overflow-style: none;
  border-bottom: 1px solid #c5c5c5;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); }
  .mobile-calendar::-webkit-scrollbar {
    display: none; }

.tutorial-wrap-outer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100000; }
  .tutorial-wrap-outer .background-hittable {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    cursor: pointer;
    z-index: -99; }

.link-to-ac {
  font-family: "WorkSans", sans-serif;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.8);
  color: #c5c5c5;
  z-index: 1001;
  position: absolute;
  top: 165px;
  right: 0;
  padding: 6px; }
  .link-to-ac a {
    color: white; }
    .link-to-ac a:hover {
      text-decoration: none; }
  @media (max-width: 800px) {
    .link-to-ac {
      background: rgba(0, 0, 0, 0.8);
      top: 80px;
      right: unset;
      left: 0;
      z-index: 1; } }
</style>
