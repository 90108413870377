<script>
  // # # # # # # # # # # # # #
  //
  //  Material Item
  //
  // # # # # # # # # # # # # #

  // *** IMPORTS
  import get from "lodash/get"
  import { urlFor } from "../sanity.js"

  // *** COMPONENTS
  import InlineSiteOverlay from "../overlays/InlineSiteOverlay.svelte"

  // *** GLOBAL
  import { FORMATMAP } from "../global.js"

  // *** PROPS
  export let item = {}

  // *** VARIABLES
  let url = ""
  let inlineSiteActive = false

  const makeUrl = ref =>
    "https://cdn.sanity.io/files/1hwmqt2a/production/" +
    ref.substring(5).replace("-", ".")

  switch (item._type) {
    case "imageBlock":
      url = urlFor(get(item, "image.asset", {})).url()
      break
    case "audioBlock":
      url = makeUrl(get(item, "audioFile.asset._ref", ""))
      break
    case "fileBlock":
      url = makeUrl(get(item, "file.asset._ref", ""))
      break
    case "pdfBlock":
      url = makeUrl(get(item, "pdfFile.asset._ref", ""))
      break
    case "videoBlock":
      url = makeUrl(get(item, "videoFile.asset._ref", ""))
      break
    case "inlineSiteBlock":
      url = get(item, "url", "")
      break
    case "linkBlock":
      url = get(item, "url", "")
      break
  }
</script>

{#if item._type == "inlineSiteBlock"}
  <div
    aria-label={item.title + ", open site in popup window"}
    class="material-item"
    role="button"
    tabindex="0"
    on:click={e => {
      inlineSiteActive = true
    }}
  >
    <div class="row">
      <h2 class="title">{item.title}</h2>
      <div class="elips" role="presentation">
        
      </div>
      <div class="format">
        {item._type === "fileBlock" || item._type === "linkBlock"
          ? item.fileType
          : FORMATMAP[item._type]}
      </div>
    </div>
  </div>
{:else}
  <a
    href={url}
    target="_blank"
    download
    aria-label={item.title}
    class="material-item"
  >
    <div class="row">
      <h2 class="title">{item.title}</h2>
      <div class="elips" role="presentation">
        
      </div>
      <div class="format">
        {item._type === "fileBlock" || item._type === "linkBlock"
          ? item.fileType
          : FORMATMAP[item._type]}
      </div>
    </div>
  </a>
{/if}

{#if inlineSiteActive}
  <InlineSiteOverlay
    {url}
    on:close={e => {
      inlineSiteActive = false
    }}
  />
{/if}

<style lang="scss">.material-item {
  width: 100%;
  background: #f0f0f0;
  color: black;
  display: block;
  text-decoration: none;
  user-select: none;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 14px;
  cursor: pointer; }
  .material-item:first-of-type {
    margin-top: 6px; }
  .material-item .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .material-item .row .title {
      font-family: "WorkSans", sans-serif;
      font-weight: 600;
      white-space: nowrap;
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis; }
    .material-item .row .elips {
      margin-left: 6px;
      margin-right: 6px;
      width: 90%;
      height: 1px;
      white-space: nowrap;
      overflow: hidden;
      flex-shrink: 99999;
      color: #999999;
      border-bottom: 1px dotted #999999; }
    .material-item .row .format {
      white-space: nowrap;
      color: #999999;
      display: flex;
      align-items: center; }
  .material-item:hover {
    background: #c5c5c5; }
  .material-item.header {
    border-bottom: 1px dotted #c5c5c5;
    cursor: default; }
    .material-item.header .archive-link {
      color: #999999;
      text-decoration: underline; }
    .material-item.header:hover {
      background: unset; }
</style>
